.radio-button-element-content {
  span:nth-of-type(2) {
    width: 98% !important;
  }
}

.hide-timepicker-now-btn {
  .ant-picker-ranges {
    .ant-picker-now {
      display: none !important;
    }
  }
}
