@import '../colors';

.accommodations-table {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: inherit;
  }

  &__icon-column {
    text-align: center;
    width: 50px;
    white-space: nowrap;
  }

  &__row:hover {
    background-color: #fff;
  }

  &__row {
    transition: all 0.3s;
    visibility: visible;
    opacity: 1;

    &--selected {
      background-color: #e6f7ff !important;
    }

    &--collapsed {
      //  visibility: collapse;
      display: none;
      opacity: 0;
    }
  }

  &__closed-row {
    background-color: #fff !important;
  }

  &__expandable-row {
    color: map-get($colors, 'azure');
    font-weight: 600;
    background-color: #fff !important;
  }

  &__title-row {
    background-color: map-get($colors, 'blue') !important;
    color: #fff;
    font-weight: 600;

    & td {
      border-bottom: none;
    }

    &:hover td {
      background-color: map-get($colors, 'blue') !important;
    }
  }

  &__dashed-column {
    position: relative;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-right: 1px dashed #c0c8d5;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 50%;
      border-bottom: 1px dashed #c0c8d5;
    }

    &--last {
      &::after {
        height: 50%;
      }
    }
  }

  & .ant-table-tbody > tr > td {
    border-left: none;
    border-bottom: none;
  }
}
