.table-no-padding {
  .ant-table-content {
    > table {
      padding: 0 !important;
    }
  }
  .ant-table {
    margin: 0 !important;
  }
}

.credit-note-expand-table > td:first-child {
  padding: 0 !important;
}

.credit-note-table-top {
  .ant-table-content > table {
    padding-top: 0 !important;
  }
}

.no-hover.ant-table-row:hover {
  background-color: transparent !important;
  cursor: default !important;
}
