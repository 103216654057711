.add-bill-modal {
  &__available-addon {
    & nz-collapse {
      background-color: unset;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #d9d9d9;

      &.ant-collapse
        > .ant-collapse-item.ant-collapse-item-active
        > .ant-collapse-header {
        border-bottom: 0;
      }

      &.ant-collapse > .ant-collapse-item {
        border-radius: 0;
      }

      &.ant-collapse-ghost
        > .ant-collapse-item
        > .ant-collapse-content
        > .ant-collapse-content-box {
        padding: 12px 0;
      }
    }
  }
}
