@import './../colors';

.reservation-details-cash-desk-total {
  .total-in {
    & *:not(.symbol) {
      color: map-get($colors, 'azure');
    }
  }

  .total-out {
    & *:not(.symbol) {
      color: map-get($colors, 'red');
    }
  }

  .total-global {
    & * {
      color: map-get($colors, 'blue');
    }
  }
}
