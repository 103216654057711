nz-input-number {
  &[nzborderless] {
    border: 0;
    box-shadow: none;
    background-color: transparent;

    & .ant-input-number-handler-wrap {
      display: none;
    }

    & input {
      text-align: center;
    }
  }

  &.no-action-step.ant-input-number,
  .no-action-step.ant-input-number-focused {
    &:hover .ant-input-number-handler-wrap,
    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  &.squared {
    //Input numerico quadrato{
    input {
      padding: 0;
      text-align: center;
    }
  }
}
