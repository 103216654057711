@import '../scss/colors';
@import '../variables';
@import './breakpoints';

.ant-calendar-cell {
  padding: 3px 0 !important;
}

//fix float td background color
.ant-table-td-left-sticky,
.ant-table-td-right-sticky {
  background: inherit;
}

// fix per crm new price quotation tabs
.crm-cart-tabs .ant-tabs-content {
  padding: 20px 0px;
  background: $body--background_color;
}

// fix font formatting
.ant-select-search__field__placeholder,
.ant-select-selection__placeholder {
  color: #bfbfbf !important;
}

// fix tasti disabilitati
.ant-btn[disabled]:not(.ant-btn-link) {
  background-color: #f5f5f5 !important;
  border: 1px solid #d9d9d9 !important;

  & * {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

// tabs border fix in crm
.crm-cart-tabs {
  .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }

  & .ant-tabs-nav {
    border-bottom: 2px solid #e8e8e8;
    height: 39px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
    height: 39px;
    border: 2px solid #e8e8e8;
  }

  &.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-wrap {
    height: 40px;
  }

  &.ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab-active {
    height: 39px;
    border-bottom: none;
  }
}

// Tabella del dettaglio prenotazioni a cazzo di cane
#myTableExcel {
  .ant-collapse-header {
    border-radius: 6px 6px 0 0;
  }
}

#bulk-update-calendar {
  & .ant-table table {
    border-spacing: 0px 1px !important;
  }
}

// tabella responsive lista prenotazioni
#reservation_list_table_responsive {
  & table {
    padding: 0 !important;
  }

  & .ant-table-tbody > tr > td {
    padding: 20px 2px;
    border-left: none;
  }

  & .ant-table-thead > tr > th {
    display: none;
    border-left: none;
  }

  & .ant-table-body tr {
    background-color: #f8f9fb;
    &.expanded {
      background-color: #efefef;
    }
  }

  & .ant-table-body tr.ant-table-expanded-row {
    background-color: #fff;

    & > td {
      vertical-align: initial;
    }
  }

  & .ant-table-row-expand-icon {
    transform: scale(1.6);
  }
}

// fix sorter table

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on
  *,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on
  * {
  color: #1890ff;
}

.reservation-list-table .ant-table-column-sorters {
  & .ant-table-column-sorter {
    display: block;
    margin-bottom: 6px;
  }

  & * {
    font-size: 9px;
  }
}

// fix for select item disabled

.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
}

// all'occorrenza rimuovo lo scroll x dalla tabella lista prenotazioni
.reservations-list-table-scroll.enabled {
  .ant-table {
    overflow-x: unset;
  }
}

//fix tab-link color
.ant-tabs-tab a {
  color: #334455;
}
#container-tabset .ant-tabs-tab-active {
  color: #1890ff;
}
#container-tabset .ant-tabs-tab:hover {
  color: #1890ff;
}

// multi estimate sostituzione icona chiusura modale

.multi-estimate-modal {
  & .ant-modal-close {
    cursor: pointer;
    padding-right: 5px;

    &::after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f00d';
      font-size: 16px;
      position: absolute;
      color: #fff;
      top: 25%;
      transform: translateY(-25%);
    }

    &-icon {
      display: none;
    }
  }
}

.reservation-modal {
  .ag-checkbox-input-wrapper {
    margin-left: 0;
  }

  .ag-theme-material .ag-header-cell,
  .ag-theme-material .ag-header-group-cell {
    padding-left: 0px;
  }

  .checkbox-cell {
    .ag-selection-checkbox {
      margin-right: 0 !important;
    }
  }

  .checkbox-header {
    padding: 0;
    padding-left: 0 !important;

    .ag-checkbox {
      justify-content: center;
      margin-right: 0 !important;
    }
    .ag-cell-label-container {
      display: none;
    }
  }
  // .shouldDisable {
  //   .ag-center-cols-viewport,
  //   .ag-center-cols-clipper,
  //   .ag-body-viewport,
  //   .ag-root.ag-layout-auto-height,
  //   .ag-root-wrapper-body,
  //   .ag-root-wrapper {
  //     overflow: initial !important;
  //   }
  // }
  // .ag-center-cols-viewport {
  //   overflow-y: scroll !important;
  // }

  // /* Hide scrollbar for Chrome, Safari and Opera */
  // .ag-center-cols-viewport::-webkit-scrollbar {
  //   display: none;
  // }

  // /* Hide scrollbar for IE, Edge and Firefox */
  // .ag-center-cols-viewport {
  //   -ms-overflow-style: none; /* IE and Edge */
  //   scrollbar-width: none; /* Firefox */
  // }
}

//sostituzione icona chiusura modale

.reservation-modal,
.reservation-modal-meals,
.by-customized-modal-close-icon, // <-- Usare questa, non crearne altre
.reservation-details-estimate-modal,
.change-date-estimate-modal {
  & .ant-modal-close {
    cursor: pointer;
    padding-right: 5px;

    &::after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f00d';
      font-size: 16px;
      position: absolute;
      color: #fff;
      top: 25%;
      transform: translateY(-25%);
    }

    &-icon {
      display: none;
    }
  }
}

//Modale sezione sospesi
#suspendedTable {
  .ant-table-thead > tr > th {
    border: 1px solid #ffffff !important;
    font-family: 'Avenir Next LT Pro Regular' !important;
    font-size: 11px !important;
    color: #707376 !important;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px !important;
    font-family: 'Avenir Next LT Pro Regular' !important;
  }
  .icon-rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .div-day {
    display: flex;
    margin-left: 47px;
    padding: 5px 0px;
    & i {
      margin-top: 2px;
      margin-right: 13px;
    }
    &::before {
      content: ' ';
      width: 1px;
      display: block !important;
      height: 42px;
      margin-left: 52px;
      margin-top: -3px;
      border-left: none !important;
      position: absolute;
      left: 12px;
      background-image: linear-gradient(#9299a2 16%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 17px 8px;
      background-repeat: repeat-y;
      z-index: 999;
    }
  }
  .div-day-first {
    display: flex;
    margin-left: 46px;
    padding: 5px 0px;
    & i {
      margin-top: 2px;
      margin-right: 13px;
    }
    &::before {
      content: ' ';
      width: 1px;
      display: block !important;
      height: 58px;
      margin-left: 52px;
      margin-top: -18px;
      border-left: none !important;
      position: absolute;
      left: 12px;
      background-image: linear-gradient(#9299a2 16%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 17px 8px;
      background-repeat: repeat-y;
      z-index: 999;
    }
  }
  .day-divider {
    height: 1px;
    width: 100%;
    padding: 0px 84px;
    position: absolute;
    & div {
      background: #d3d8e2;
      height: 1px;
    }
  }
  .tr-day {
    position: relative;
  }
  .td-day {
    padding: 0px;
    margin: 0px;
  }

  .td-no-border {
    border: none !important;
    background: #f6f8fa;
    color: #334455 !important;
  }
  .tr-day .td-no-border {
    padding-bottom: 0px;
  }
  .td-no-border-left-rigth {
    border-left: none !important;
    border-right: none !important;
    background: #f6f8fa;
    color: #334455 !important;
  }

  .tr-no-border {
    border: none !important;
  }

  .td-color {
    background: #f0f4f8;
    color: #334455 !important;
    border: none;
  }
  .td-radius-r {
    border-radius: 0px 5px 5px 0px;
  }
  .td-payment-center {
    text-align: center !important;
  }
  .text-color {
    color: #2885f8 !important;
    font-weight: bold;
  }
  .td-padding {
    padding-top: 0px !important;
  }
  .tr-divider {
    background: #ffffff !important;
    border: none !important;
    padding: 4px;
  }
  .ant-table-row-expand-icon {
    background: #334455;
    color: #ffffff;
    font-weight: bold;
  }
  .td-no-border .ant-table-row-expand-icon {
    float: right;
  }
}

// button save after font default format
.button--save span {
  color: #fff;
}

// per riportare gli stili applicati da alcune classi su nz-card alla classe ant-card-body
.no-pt .ant-card-body {
  padding-top: 0 !important;
}

// no arrow dropdown
.no-arrow-dropdown i {
  display: none;
}

// testo bianco su switch della sezione cassa
#cash-desk {
  .view-mode-switch .ant-radio-button-checked + span {
    color: #fff;

    & i * {
      color: #fff;
    }
  }
}

//small dropdown without ellipsis overflow
.no-ellipsis-overflow .ant-select-item-option-content {
  text-overflow: unset;
}

//app-shell languages flags
.nz-options-without-padding {
  & li {
    padding: 3px 0;
    text-align: center;
  }
}

//overflow nella modale di visualizzazione formato sdi della fattura da 900px in giù
@media only screen and (max-width: 900px) {
  .show-invoice-sdi-format .ant-modal-body {
    overflow-x: scroll;
  }
}

//trasparenza colore tab deselezionate
#container-tabset .ant-tabs-tab:not(.ant-tabs-tab-active) {
  opacity: 0.6;
}

// form anagrafica aziende override
#company-details-form {
  & .ant-input-group-addon {
    padding: 0px !important;
    min-width: 65px;
  }
}

// radio group modale nuovo pagamento
#payment-type-selection .ant-radio-button-wrapper {
  padding: 0 8px;
}

//correzione grafica select modale pagamenti per selezione sezionale e numero fattura
.invoice-number-selection {
  &__number {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 32px;
    line-height: 28px;

    &.default-radius {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
  }

  &__sectional {
    width: 60px !important;
    margin-bottom: 4px;

    & .ant-select-selector {
      border-radius: 4px 0 0 4px;
    }
  }
}

// flex children form control
.form-control-flex-children {
  & .ant-form-item-children {
    display: flex;
    align-items: center;
  }
}

// rimozione line height
.ant-tabs-extra-content {
  line-height: 1;
}

//tabset senza tabs
.hidden-tabset > .ant-tabs-top-bar {
  display: none;
}

.filters-row__box__selects__select {
  .beddy-multi-select {
    min-width: 140px !important;
    &.ant-select-open.ant-select-multiple .ant-select-selection-item-disabled {
      display: flex !important;
      justify-content: space-between !important;
    }
  }
}

//multiselect
.beddy-multi-select.ant-select-open
  > .ant-select-selector
  > .ant-select-selection-item {
  display: none;
}

.beddy-multi-select {
  width: 100%;

  // min-width: 230px;
  .ant-select-selection--multiple {
    min-height: 32px !important;
  }
  &.ant-select-open
    .ant-select-selection--multiple
    .ant-select-selection__choice__content {
    opacity: 0;
  }

  .ant-select-selection__rendered {
    background-color: transparent;
    z-index: 0;
    & .ant-select-selection__choice {
      background: #fff;
      border: none;
    }
    & .ant-select-search.ant-select-search--inline {
      position: absolute;
    }
  }
  .ant-select-selector {
    flex-wrap: nowrap;
  }
}

// fix height select in addons

.addons-details__add-form {
  .ant-select-selection--single {
    height: 34px !important;
  }
}

.addons-details {
  &__steps {
    .ant-steps {
      margin-left: -10px;
    }
  }

  &__item-limitations {
    .ant-form-item {
      .ant-form-item-control-wrapper {
        .ant-form-item-control {
          line-height: 26px !important;
        }
      }
    }
  }
}

// fix table reservations in dashboard
#dashboardReservation {
  .ant-card-body {
    padding: 15px 0 !important;

    nz-pagination {
      padding-right: 25px;
    }
  }
}

@media screen and (max-width: 480px) {
  .new-accommodation-details-form,
  .edit-accommodation-details-form {
    .card-with-shadow {
      .ant-card-body {
        .ant-steps {
          .ant-steps-item-content {
            .ant-steps-item-title {
              margin-top: -10px !important;
            }
          }
        }
      }
    }
  }
}

.accommodation-resource__select {
  .ant-select-selector {
    border: 2px solid #dee2ed !important;
    height: 35px !important;
  }
}

.rateplans-select__select {
  .ant-select-selector {
    border: 2px solid #dee2ed !important;
    height: 34px !important;
  }
}

// new istat template

.istat-panel-custom-style {
  > .ant-collapse-content > .ant-collapse-content-box {
    border-top: 1px solid #dee2ed;
    padding: 16px 16px 0 16px;
  }
}

@media screen and (max-width: 576px) {
  .panel-custom-style {
    .ant-collapse-header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 12px 5px !important;
    }
  }
}

.card-guest-list-container {
  .ant-card {
    background: none !important;
    border: 0 none !important;

    .ant-card-body {
      padding: 0px !important;
    }
  }

  .ant-divider {
    display: none;
  }

  .istat-guest-list-container {
    .istat-guest-collapse {
      background: transparent;
      .ant-collapse-borderless {
        background: transparent;
      }
    }
    .card-with-shadow {
      border: 0 none;
      box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12) !important;
      background: #fff !important;
      overflow: visible !important;

      .ant-card-body {
        .by-card-header {
          padding: 40px 20px !important;
        }

        div.by-mt-2 {
          border-top: 1px solid #dee2ed;
          padding: 30px;

          &.text-align--end {
            padding: 30px 0 30px 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .istat-guest-list-container {
    .card-with-shadow {
      .ant-card-body {
        div.by-mt-2 {
          padding: 30px 10px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .istat-single-guest {
    .ant-list-item-meta {
      flex-direction: column;
    }
  }
}

.ant-modal-body {
  by-guests-form {
    .guests-configuration__divider {
      display: none;
    }
  }
}

// rimuovo alcuni stili di ant-design quando l'uploader ha il custom content
nz-upload.upload-with-custom-content {
  & .ant-upload.ant-upload-drag {
    background: transparent;
    border: none;
  }
}

// date picker del form dei pagamenti
.payment-form-datepicker .ant-calendar-picker-input.ant-input {
  padding: 4px 36px 4px 11px;
}

// fix mobile per modale privacy

@media (max-width: 767px) {
  .privacy-policy-modal {
    max-width: none !important;
  }
}

// fix select responsive
.ant-select-item-option-content {
  line-height: 25px;
  white-space: normal;
}

// fix range-picker in extra
.addons-details__range-date {
  nz-picker {
    .ant-calendar-picker {
      width: 100%;
    }
  }
}

// fix grafico per input group con il bottone
.input-group-with-button {
  & .ant-input-group-addon {
    padding: 0;
    border: none;

    & button.ant-btn {
      border-radius: 0 4px 4px 0;
    }
  }
}

// nascondere lo scroll delle tabs
.scroll-disabled-tabs {
  & .ant-tabs-nav-container-scrolling {
    padding: 0;
  }
  & .ant-tabs-tab-arrow-show {
    display: none;
  }
}

// style del popover delle more properties su cassa
.button-extra-content-cash-more-overlay {
  & .ant-popover-inner-content {
    padding: 0;
    overflow: hidden;
  }
}

// style delle opzioni del compoente filtro per partita iva
.vat-code-filter {
  &.ant-select-dropdown.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item:not(:first-child) {
    text-transform: uppercase;
  }
}

// style del preset del range picker nei filtri
.range-picker-preset {
  .ant-picker-ranges {
    display: none;
  }

  .ant-picker-footer-extra:not(:last-child) {
    border: none;
  }

  .ant-picker-panel {
    border-bottom: none;
  }
}

// style del nzAllowClear delle select
nz-select:not(.nz-allow-clear-default) {
  .ant-select-close-icon:not(.compare-select) {
    &::after {
      font-family: 'Font Awesome 5 Pro';
      content: '\f00d';
      transition: all 0.1s ease-in-out;
      font-weight: 900;
      color: map-get($colors, 'red') !important;
      font-size: 14px;
    }
    svg {
      display: none;
    }
  }
}

// stile nzAllowClear della select compare delle statistiche
nz-select.compare-select {
  .ant-select-clear {
    background: #a3a3a3;
    &::after {
      font-family: 'Font Awesome 5 Pro';
      content: '\f00d';
      transition: all 0.1s ease-in-out;
      font-weight: 900;
      color: #fff;
      font-size: 14px;
    }
    svg {
      display: none;
    }
  }
}

// select loading color
.ant-select-arrow .anticon-loading {
  color: map-get($colors, 'azure');
}

// fix table log aggiornamenti

.logs-aggiornamenti__data {
  .logsTable {
    .ant-table-thead {
      tr {
        background: none;

        th {
          background: none;
          text-align: left;
          font-size: 10px !important;
          padding: 10px;
          border-left: 0 none !important;
          border-bottom: 1px solid #a6a9b0 !important;
        }
      }
    }
    .ant-table-content {
      padding: 20px 20px 30px 20px;
      background: #f1f4f9;

      .ant-table-tbody {
        tr:nth-child(even) {
          background: #f1f4f9;
        }

        tr:nth-child(odd) {
          background: #eaeff5;
        }
        tr {
          td {
            border-left: 0 none !important;
          }
        }
      }
    }
  }
}

//fix modal log details

.logsTableDetails {
  .ant-table-thead {
    tr {
      background: none;

      th {
        background: none;
        text-align: left;
        font-size: 10px !important;
        padding: 10px;
        border-left: 0 none !important;
        border-bottom: 1px solid #a6a9b0 !important;
      }
    }
  }

  .ant-table-content {
    .ant-table-tbody {
      tr {
        td {
          border-left: 0 none !important;
        }
      }
    }
  }
}

.modal-log-details {
  .ant-modal-header {
    border-bottom: 0 none;
  }
}

.page-header-modal-details {
  .page-header-component {
    margin-bottom: 0;
  }
}
// style nzRequired

.ant-form-item-label > label.ant-form-item-required::before {
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required::after {
  color: #f5222d !important;
  font-size: 14px !important;
  font-family: SimSun, sans-serif !important;
  margin-left: 5px !important;
  line-height: 1 !important;
  content: '*' !important;
}

.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: unset;
}

.propertyTreatmentsTable {
  .ant-table-content {
    .ant-table-tbody > tr {
      &:hover {
        background: #e6f7ff;
      }

      > td {
        background: none;
      }
    }
  }
}

// FIX ALTERNANZA COLORI TR

.customizable-table {
  .ant-table-tbody > tr > td.ant-table-cell-fix-left,
  .ant-table-tbody > tr > td.ant-table-cell-fix-right {
    background: none;
  }

  .ant-table-tbody > tr > td.ant-table-cell-fix-left:last-child,
  .ant-table-tbody > tr > td.ant-table-cell-fix-right:last-child {
    background: #ffffff;
    transition: none;
  }
  .ant-table-tbody > tr:hover,
  .ant-table-tbody > tr:hover > td.ant-table-cell-fix-left:last-child,
  .ant-table-tbody > tr:hover > td.ant-table-cell-fix-right:last-child {
    background: #e6f7ff;
    &.ant-table-placeholder {
      background: none;
    }
  }
}

// FIx RADIO BUTTON

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -2px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 91%;
  padding: 2px 0;
  background-color: #dee2ed;
  transition: background-color 0.3s;
  content: '';
}

.cancellation-policy__form {
  .first-label-question {
    label {
      height: auto;
    }
  }
}

.first-label-question {
  label {
    height: auto;
  }
}

.newReservationFormRow {
  .ant-form-item-control-input {
    .ant-picker-input {
      height: 22px;

      input {
        margin-top: 2px;
        margin-left: 4px;
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        //margin-top: 2px;
        margin-right: 3px;
      }
    }
  }
}

.discount_value {
  .ant-input-number-input {
    height: 28px;
  }
}

.header-tabs__radio-container__control.ant-radio-group.ant-radio-group-solid {
  .ant-radio-button-wrapper {
    background: #32415b;
    border-radius: 0;
    padding: 5px 5px;
    border: 1px solid #9fa7bc;
    width: 80px;
    height: 37px;
    line-height: 2;
    text-align: center;
    color: #9fa7bc;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: #fff !important;
    border-color: #fff;
  }
}

.ant-btn-text.stats-filter-button:hover,
.ant-btn-text.stats-filter-button:focus {
  background: transparent;
  border: none;
}

.filters-row__box__selects__select {
  .ant-select-selector {
    border: none;
    flex-wrap: nowrap;
    padding: 0;
    background: #eef1f5 !important;
  }

  .ant-select-selection-item.ant-select-selection-item-disabled {
    background: #fafafa;
    border: none;
    height: 100%;
    margin: 0;
    line-height: 2.3;
    width: 100%;
    border-radius: 4px;
    background: #eef1f5;
  }

  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    border: none;
  }

  nz-select-arrow {
    i {
      color: #334455;
    }
  }
}

nz-select.compare-select {
  min-width: 120px;
  .ant-select-selector {
    border: none !important;
    background: #a3a3a3 !important;
    color: #ffff !important;
  }

  .ant-select-selection-item {
    line-height: 2.5 !important;
  }

  nz-select-arrow {
    i {
      color: #ffffff !important;
    }
  }

  .ant-select-selection-placeholder {
    line-height: 2.5 !important;
  }
}

.page-header-calendar-date {
  .ant-picker-calendar-header
    .ant-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
    border-right: 1px solid #40a9ff;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #334455;
  font-weight: 600;
  background-color: #f5f5f5;
  &:hover {
    background-color: #e6f7ff;
  }
}

.login-form {
  .ant-input-prefix {
    display: flex;
    flex: none;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 12px;
    z-index: 2;
    display: flex;
    align-items: center;
    color: #334455;
    line-height: 0;
    transform: translateY(-50%);
  }

  .ant-input-affix-wrapper input.ant-input {
    padding: 4px 11px;
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 30px;
  }
}

.discount-value-bulk-update {
  .ant-input-number-input {
    height: 28px;
  }
}

.rateplans-select__select {
  &.ant-select-multiple .ant-select-selection-item-disabled {
    background: none;
    border: 0 none;
  }
}

.bulk-update-rate {
  &.ant-table-tbody > tr > td {
    padding: 11px 6px;
  }
}

.bulk-update-rate-table {
  .ant-table table {
    min-width: 1200px;
  }
}

// stile icona degli inpit disabilitati editabili al doppio click
.disabled-edit-icon {
  cursor: url('https://cdn.beddy.io/fonts/fontawesome/images/pencil-alt-solid-small.svg'),
    auto !important;
  * {
    cursor: unset !important;
  }
  [disabled] {
    pointer-events: none;
  }
}

// stile degli input disabilitati
.ant-input[disabled] {
  color: #868686;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #868686;
}

.no-border-select {
  .anticon svg {
    display: none;
  }

  .anticon {
    transform: rotate(180deg);
    &::after {
      content: '\f2ec';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      color: $crm-arrow-icon--color;
      font-size: 6px;
      vertical-align: middle;
    }
  }

  nz-select-top-control {
    border: none !important;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    outline: inherit;
    box-shadow: inherit;
    border: none;
  }

  &.revenue-select {
    .ant-select-selection-item {
      line-height: 15px !important;
    }

    nz-select-item > div > strong {
      font-size: 13px !important;
    }

    .ant-select-selector {
      background: transparent;
      color: map-get($colors, 'azure') !important;
    }
  }

  &.crm-rate-select {
    .ant-select-selection-item {
      font-size: 14px;
      font-weight: bold;
      color: map-get($colors, 'azure');
    }

    nz-select-top-control {
      border-bottom: 1px solid #e7e8ec !important;
      height: 28px !important;
      border-radius: 0 !important;
      padding: 0px 5px !important;
    }
  }
}

.crm-select {
  .anticon svg {
    display: none;
  }

  .anticon {
    &::after {
      content: '\f107';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 12px;
      color: #e09a01;
      vertical-align: middle;
    }
  }
}

.multi-select {
  .ant-select-selector {
    .ant-select-selection-item-disabled + .ant-select-selection-item-disabled {
      display: none;
    }
  }
}

// No handler buttons for input number
.ant-input-number.no-handler-button:hover .ant-input-number-handler-wrap {
  opacity: 0;
}

.stats {
  .anticon svg {
    display: none;
  }

  .ant-tabs-nav-operations {
    transition: color 0.3s;
    background-color: #e8e8e8;
    cursor: pointer;

    & .anticon.anticon-ellipsis {
      &::after {
        content: '\f0d7';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
      }
    }

    &:hover .anticon.anticon-ellipsis::after {
      color: #047bf8;
    }
  }
}

.checkbox-colors-reversed {
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #047bf8;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #fff;
  }
}

.collapse-without-padding {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }
}

.popover-without-padding {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.ant-collapse.crm-search-result-collapse {
  border: 0;

  .ant-collapse-header {
    border-bottom: 0 !important;
  }

  .ant-collapse-item {
    border-bottom: 0;
    border-radius: 4px;
  }
}

// stile range picker responsive
.range-picker-resposnive-close-icon {
  display: none;
}

.by-unassociated-rooms {
  .ant-alert-info {
    background-color: white !important;
  }
}

@media only screen and (max-width: 700px) {
  .range-picker-resposnive-close-icon {
    display: block;
  }
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }

  .ant-picker-range-arrow {
    display: none !important;
  }

  .ant-select-clear {
    opacity: 1;
  }
}
// stile dei collapse in responsive
@media not screen and (map-get($breakpoints, 'lg')) {
  .collapse-padding-responsive {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 10px;
    }
  }
}
