@use '../grid-breakpoints' as breakpoints;

$tabHeight: 72px;
$tabWidth: 255px;
$beforeXLarge: map-get(breakpoints.$gridBreakpoints, 'before-xLarge');

.reservation-details-accommodations-tabset {
  overflow: initial;

  & > .ant-tabs-nav * {
    overflow: initial !important;
  }

  & > .ant-tabs-nav {
    display: flex;
    border-bottom: 1px solid #c1ced6;
    background-color: #fff;
    position: relative;
    margin-bottom: 50px;
    transition: all 0.3s;
    overflow: initial;
    overflow-x: clip;

    &::before {
      display: none;
    }

    & .ant-tabs-nav-wrap {
      flex: initial !important;
    }

    & .ant-tabs-extra-content {
      line-height: $tabHeight;
      background-color: #afb6bc;
      right: 0;
      z-index: 999;

      &.by-absolute {
        position: absolute;
      }
    }

    & .ant-tabs-tab {
      margin: 0 !important;
      border: unset;
      border-radius: unset;
      min-width: $tabWidth;
      height: $tabHeight !important;
      line-height: $tabHeight !important;
      padding: 0;
      text-align: center;
      background-color: #fff;
      transition: transform 0.1s;
      transform-origin: bottom;
      opacity: 0.5;

      & .ant-tabs-tab-btn {
        width: 100%;
      }

      &.ant-tabs-tab-active {
        transform: scaleY(1.17) translateY(1px);
        opacity: 1;
        border: 1px solid #c1ced6;
        border-bottom: unset;
        border-radius: 5px 5px 0 0;
        background: linear-gradient(
          rgb(255, 255, 255) 14%,
          rgb(241, 244, 249) 100%
        );

        & > * {
          transform: scaleY(0.863);
        }
      }

      &::selection {
        color: inherit;
        background: inherit;
      }

      & .custom-tab-template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 17px 0 30px;
        height: 100%;

        & .custom-tab {
          &__status {
            width: 23px;
            height: 23px;
            background-color: black;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            line-height: 23px;
          }

          &__body {
            text-align: left;

            & span {
              display: block;
              line-height: 1.11;
              text-transform: capitalize;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 125px;
            }

            &--title {
              font-weight: bold;
              color: #000;
              letter-spacing: -0.6px;
              font-size: 13px;
              margin-bottom: 5px;
            }

            &--subtitle {
              color: #828282;
              font-size: 12px;
            }
          }

          &__close {
            background-color: transparent;
            transition: all 0.3s;
            height: 15px;
            width: 15px;
            line-height: 1;
            border-radius: 50%;

            &:hover {
              background-color: #8282826b;
            }

            & i {
              color: #828282;
              transform: translateY(0.81px);
              font-size: 13px;
            }
          }
        }
      }

      &:first-child {
        & .custom-tab-template {
          padding: 0 17px 0 40px;
        }
      }
    }
  }
}

@media ($beforeXLarge) {
  .reservation-details-accommodations-tabset {
    & > .ant-tabs-nav {
      display: none;
    }
  }
}
