.by-mr-2px {
  margin-right: 2px !important;
}

.by-ml-2px {
  margin-left: 2px !important;
}

.by-mr-2 {
  margin-right: 20px !important;
}

.by-mr-25 {
  margin-right: 25px !important;
}

.by-mt-0 {
  margin-top: 0px !important;
}

.by-mt-30 {
  margin-top: 30px !important;
}

.by-mr-30 {
  margin-right: 30px !important;
}

.by-mr-3 {
  margin-right: 3px !important;
}

.by-ml-3 {
  margin-left: 3px !important;
}

.by-mr-5 {
  margin-right: 5px !important;
}

.by-mr-15 {
  margin-right: 15px !important;
}

.by-mr-10 {
  margin-right: 10px !important;
}

.by-ml-2 {
  margin-left: 20px !important;
}

.by-ml-4 {
  margin-left: 40px !important;
}

.by-ml-1 {
  margin-left: 10px !important;
}
.by-mr-1 {
  margin-right: 10px !important;
}

.by-ml-5 {
  margin-left: 5px !important;
}

.by-ml-10 {
  margin-left: 10px !important;
}

.by-ml-15 {
  margin-left: 15px !important;
}

.by-mb-3 {
  margin-bottom: 3px !important;
}

.by-mb-5 {
  margin-bottom: 5px !important;
}

.by-mb-15 {
  margin-bottom: 15px !important;
}

.by-mb-30 {
  margin-bottom: 30px !important;
}

.by-mb-40 {
  margin-bottom: 40px !important;
}

.by-mt-2 {
  margin-top: 20px !important;
}

.by-mt-2px {
  margin-top: 2px !important;
}

.by-mb-2px {
  margin-bottom: 2px !important;
}
.by-mb-20 {
  margin-bottom: 20px !important;
}

.by-mt-40 {
  margin-top: 40px !important;
}

.by-mt-60 {
  margin-top: 60px !important;
}

.by-mt-80 {
  margin-top: 80px !important;
}

.by-mt-5 {
  margin-top: 5px !important;
}

.by-mb-0 {
  margin-bottom: 0px !important;
}

.by-mb-2 {
  margin-bottom: 20px !important;
}

.by-mb-10 {
  margin-bottom: 10px !important;
}

.by-mb-40 {
  margin-bottom: 40px !important;
}

.by-mb-50 {
  margin-bottom: 50px !important;
}

.by-mt-25 {
  margin-top: 25px !important;
}

.by-mt-4 {
  margin-top: 1.2rem !important;
}

.by-mt-4px {
  margin-top: 4px;
}

.by-mt-6 {
  margin-top: 6px !important;
}

.by-mt-10 {
  margin-top: 10px !important;
}

.by-mt-15 {
  margin-top: 15px !important;
}

.by-mt-20 {
  margin-top: 20px !important;
}

.by-mt-16 {
  margin-top: 16px !important;
}

.by-mt-60 {
  margin-top: 60px !important;
}

.by-mb-4 {
  margin-bottom: 1.2rem !important;
}

@for $i from 1 through 36 {
  .by-m-#{$i} {
    margin: #{$i}px !important;
  }
}
