.more-properties {
  &__overlay {
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &__list {
    max-height: 400px;
    overflow: auto;
  }

  &__list-item {
    padding: 8px 20px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #e6f7ff;
    }
  }

  &__button {
    border: 0 none;
    background: #e8e8e8;
    height: 54px;
    border-radius: 0px;
    transition: none;
  }
}
