.customizable-table {
  & * {
    color: #334455;
    font-size: 13px;
  }

  & .ant-table-thead > tr > th {
    padding: 0 10px;
    vertical-align: middle;
    background: #fff !important;
  }

  & .ant-table-thead .ant-table-column-sorter {
    display: block;
    height: 14px;
    background: none !important;
  }

  & .ant-table-tbody > tr > td.ellipsis-actions-td {
    width: 40px;
    padding: 0;
    text-align: center;
    background: inherit !important;
  }

  & th .ant-table-column-title span {
    font-size: 11px;
  }

  & .ant-table-column-sorter .anticon-caret-up svg,
  & .ant-table-column-sorter .anticon-caret-down svg {
    font-size: 9px !important;
    margin-top: 1px !important;
  }
}
