@media screen and (max-width: 1000px) {
  .responsive-steps {
    & .ant-steps {
      counter-reset: step;
      position: relative;

      & .ant-steps-item {
        counter-increment: step;
        position: unset;

        &:last-child::after {
          content: '' counter(step) '';
          font-size: 14px;
          visibility: visible;
          position: absolute;
          left: 18px;
          top: 5px;
          color: #fff;
        }

        &::before {
          content: '' counter(step) '/';
          font-size: 14px;
          background-color: #047bf8;
          color: #fff;
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 50%;
          position: absolute;
          padding-top: 5px;
          padding-left: 4px;
        }

        & .ant-steps-icon-dot {
          display: none;
        }
      }

      &.ant-steps-dot .ant-steps-item-content {
        width: 100% !important;
        text-align: left;
        margin-top: 0;
      }

      & .ant-steps-item-icon,
      & .ant-steps-item-tail {
        display: none;
      }
    }

    & .ant-steps-item-wait,
    & .ant-steps-item-finish {
      visibility: hidden;
      flex: 0;

      & > .ant-steps-item-container {
        display: none;
      }
    }

    & .ant-steps-item-title {
      margin-top: 0px !important;
      line-height: 32px;
    }
  }
}
