@use './../grid-breakpoints';

.page-margin {
  margin: 0 -25px;

  &--responsive {
    margin: 0 0;

    @include grid-breakpoints.grid-respond-to('before-medium') {
      margin: 0 -20px;
    }
  }
}
