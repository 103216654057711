@import '../colors';

.select-round-message {
  .ant-select-arrow {
    padding-right: 10px;
  }
  .ant-select-clear {
    margin-right: 10px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 45px;
    border-radius: 30px;
    padding-top: 8px;
    padding-left: 19px;
    border: 1px solid map-get($colors, 'tabset-border');
    .ant-select-selection-search-input {
      height: 45px !important;
    }
  }
}

.custom-round-select {
  .ant-select-arrow {
    padding-right: 10px;
  }

  .ant-select-selector {
    color: #bfbfbf;
    border-radius: 25px !important;
    border: 1px solid map-get($colors, 'tabset-border') !important;
    height: 45px;
  }
}

.custom-input-round-messages {
  height: 45px;
  border-radius: 25px;
  border: 1px solid map-get($colors, 'tabset-border');
}
