$thead-border: 1px solid #e0e3ea;

.crm-search-results-table {
  &__body {
    & tr:nth-child(even) {
      background-color: rgb(248, 249, 251);
    }

    & tr:nth-child(odd) {
      background-color: #fff !important;
    }
  }

  &__th {
    & .ant-table-header-column,
    & .ant-table-header-column div {
      width: 100%;
    }

    &:not(:last-child) {
      border-right: $thead-border;
    }
  }

  & .ant-table-thead > tr > th {
    border-left: unset;
    border-bottom: 1px solid #999999;
    height: unset;
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
  }

  & .ant-table-tbody > tr > td:not(.no-min-width) {
    min-width: 120px;
    padding: 5px 10px;
  }

  & .ant-input-group-addon {
    border: none;
    padding: unset;
  }

  & table {
    padding-top: 0 !important;
  }
}

.border-right-th {
  border-right: $thead-border;
}

.border-left-th {
  border-left: $thead-border !important;
}
