.mh-72px {
  min-height: 72px;
}

@for $i from 1 through 40 {
  .by-mh-#{$i} {
    min-height: #{$i}px !important;
  }
}

.by-maxh-300 {
  max-height: 300px;
}

.by-h-auto {
  height: auto !important;
}

.by-h-100 {
  height: 100%;
}
