@use '../colors';
@use '../grid-breakpoints' as mixin;

.by-tabset.by-tabset-default
  nz-tabset[nzlinkrouter]
  .ant-tabs-nav
  .ant-tabs-tab
  a {
  padding: 24px 32px;
}

.by-tabset-default {
  nz-tabset.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    height: 53px;
    border: 1px solid transparent;
    background: none;
    margin: 0 -3px 0 0;
    transition: none;
    padding: 10px 35px 5px 35px;

    &.ant-tabs-tab-active {
      height: 54px;
      border: 1px solid map-get($colors, 'tabset-border');
      border-bottom: 0 none;
      background: map-get($colors, 'white');
      top: 0px;
      line-height: 3;
      color: map-get($colors, 'blue');
    }

    & .ant-tabs-tab-btn {
      color: map-get($colors, 'blue');
      text-shadow: unset;
    }
  }

  &__tab {
    display: flex;
    align-items: center;

    &__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      border: 1px solid map-get($colors, 'tabset-counter-border');
      color: map-get($colors, 'tabset-inactive');
      min-width: 30px;
      height: 30px;
      font-size: 12px;
      margin-right: 10px;
      z-index: 1;
      padding: 2px;

      @include mixin.grid-respond-to('before-xLarge') {
        min-width: 23px;
        height: 23px;
      }

      &--active {
        color: map-get($colors, 'white');
        background: map-get($colors, 'tabset-active');
        border-color: map-get($colors, 'tabset-active');
      }
    }
  }
}
