.bottom-title-breadcrumb {
  & by-breadcrumb {
    width: 100%;
  }

  & .breadcrumb {
    border-bottom: none !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 25px;
    min-height: unset !important;
    display: flex;
    align-items: flex-end;
  }
}
