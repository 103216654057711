.radio-group-solid-original-style {
  .ant-radio-button-wrapper {
    border-radius: 0 !important;
    padding: 5px 5px;
    line-height: 2;
    text-align: center;
  }
}

.radio-group-solid-original-style.zdlake-group-by-radio-group {
  .ant-radio-button-wrapper {
    width: 57px;
    height: 37px;
  }
}
