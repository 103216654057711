@use '../customized-scrollbar';
@use '../colors';

.select-custom-scrollbar {
  .cdk-virtual-scroll-viewport {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'grey-light'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'grey-light')
    );
  }

  .cdk-virtual-scroll-viewport:hover {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'grey-light'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'grey-dark')
    );
  }
}
