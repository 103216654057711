@use './../colors' as colors;

$colors: colors.$colors;

nz-table.booking-promotions-table {
  .ant-table-tbody > tr > td {
    border-left: none;
    padding: 20px 25px;
    border-bottom: 2px solid map-get($colors, 'booking-table-row-border');
    vertical-align: top;
  }

  .border-right {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 15px;
      right: 0;
      height: calc(100% - 30px);
      width: 1px;
      border-left: 1px solid map-get($colors, 'booking-table-row-border');
    }
  }
}
