@use '../customized-scrollbar';

.policy-languages-form {
  .ql-editor {
    height: 400px;
    overflow-y: scroll;

    @include customized-scrollbar.customizedScrollbar();
  }
}
