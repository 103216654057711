.tableau-reservation-details {
  .ant-modal-close-x {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  .ant-popover-inner-content {
    padding: 0;
    width: 345px;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 8px;
  }
  .ant-modal-close {
    cursor: default;
  }
  .ant-modal-close-icon.anticon-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .ant-modal-footer {
    background: #f3f3f5;
  }

  .notes {
    padding: 5px 20px;
    font-size: 12px;
    min-height: 30px;
    background-color: #fff;
  }

  .status {
    padding: 5px 13px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
    min-height: 64px;
    background-color: #fff;

    &__select {
      min-width: 155px;
    }

    &__empty {
      min-height: 30px;
    }

    &__info {
      padding-left: 25px;
    }
  }

  .footer {
    min-height: 25px;
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &__actions {
        display: flex;
        align-items: center;
      }
    }
  }

  .action {
    color: #000000a6;
  }
}
