@import '../scss/colors';

.beddy-custom-tabset {
  .ant-tabs-nav {
    padding-left: 20px;
    margin-bottom: 0;

    .ant-tabs-tab-active {
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
      border-right: 1px solid #e8e8e8;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;

      & .ant-tabs-tab-btn {
        color: #334455;
      }
    }
  }
}

nz-tabset.beddy-custom-tabset {
  .ant-tabs-tab {
    padding: 10px 35px 5px 35px !important;
    height: 100% !important;
    min-height: 55px;
    border-top: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
    background: none !important;
    margin: 0 -3px 0 0 !important;
    transition: none !important;

    &:last-child {
      border-right: 0 none !important;
    }

    .beddy-custom-tabset__tab-counter {
      border-radius: 50%;
      border: 1px solid #e0e3e8;
      color: #9c9c9c;
      width: 28px;
      height: 28px;
      display: inline-block;
      text-align: center;
      line-height: 28px;
      font-size: 12px;
      margin-right: 10px;
    }

    & > .beddy-custom-tabset__tab-title {
      color: #9c9c9c;
    }

    &.ant-tabs-tab-active {
      border-left: 1px solid #e8e8e8 !important;
      border-right: 1px solid #e8e8e8 !important;
      border-top: 1px solid #e8e8e8 !important;
      border-bottom: 0 none !important;
      background: #ffffff !important;
      top: 0px;
      line-height: 3;

      & .beddy-custom-tabset__tab-counter {
        color: white;
        background: #2a3144;
        border-color: #2a3144;
      }

      .beddy-custom-tabset__tab-title {
        color: #2a3144;
      }
    }
  }

  &[nzlinkrouter] {
    & .ant-tabs-tab {
      padding: 0 !important;

      & a {
        padding: 20px 30px !important;
      }
    }
  }

  &__all-unselected.beddy-custom-tabset .ant-tabs-tab.ant-tabs-tab-active {
    border: none !important;
    background-color: transparent !important;
  }

  &.mb-0 {
    .ant-tabs-nav {
      margin-bottom: 0px;
    }
  }

  .ant-tabs-nav {
    padding-left: 20px;

    .ant-tabs-tab-active {
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
      border-right: 1px solid #e8e8e8;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;

      & .ant-tabs-tab-btn {
        color: #334455;
      }
    }
  }
}

.beddy-custom-tabset__tab-content {
  .tempMessage {
    margin-left: 30px;
    background-color: #fcf9e2;
    border: 2px solid #f1e8a3;
    color: #71671b;
    border-radius: 4px;
    padding: 5px 15px;
  }
}

nz-tabset.beddy-custom-tabset {
  .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 55px;
  }
}

.card-with-shadow.beddy-custom-tabset {
  margin: -7px -25px 0 -25px;
  box-shadow: none !important;
}

nz-tabset.beddy-custom-tabset {
  margin: 20px -25px 0px -25px;

  .ant-tabs-extra-content {
    margin-right: 25px;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    opacity: 1 !important;
  }
}

nz-tabset.beddy-custom-tabset--transparent {
  & .ant-tabs-tab {
    background: transparent !important;
    border-bottom: unset !important;

    &.ant-tabs-tab-active {
      border-bottom: unset !important;
      background-image: linear-gradient(
        0deg,
        #f1f4f9 14%,
        #fafcfe 100%
      ) !important;

      & .ant-tabs-tab-btn,
      & a {
        color: #1890ff;
        text-shadow: 0 0 0.25px currentcolor;
      }
    }

    &:hover,
    &:hover a {
      color: #1890ff;
    }

    &:hover {
      color: #40a9ff;
    }

    &:not(.ant-tabs-tab-active) {
      opacity: 0.6 !important;
    }
  }
}

.beddy-custom-tabset__tab-content {
  flex-direction: row;
  align-items: flex-start;
  overflow: auto;

  .tempMessage {
    width: 400px;
  }
}

@media screen and (max-width: 810px) {
  .beddy-custom-tabset__tab-content {
    display: flex;
    justify-content: flex-start;
    .tempMessage {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .beddy-custom-tabset__tab-content {
    .tempMessage {
      width: 285px;
    }

    .beddy-custom-tabset__tab-info {
      margin-bottom: 20px;
      min-width: 50vw;
      position: relative;
    }
  }

  .beddy-custom-tabset {
    margin: 20px 0 0 0;
  }
}

@media screen and (max-width: 450px) {
  .beddy-custom-tabset__tab-content {
    .beddy-custom-tabset__tab-info {
      min-width: 100vw;
    }
  }
}

.beddy-custom-tabset__tab-content {
  background-color: #ffffff;
  width: 100%;
  padding: 25px;
  display: flex;
  border-bottom: 1px solid #e8e8e8;

  .beddy-custom-tabset__tab-info {
    padding: 0 32px;

    &:last-child {
      background-image: none;
    }

    &:not(:last-child) {
      border-right: 1px solid #e9eaee;
    }

    &-title {
      color: map-get($colors, 'grey-light');
    }

    &-value {
      font-size: 23px;
      font-weight: bold;
    }

    &-subtitle {
      color: #a6adb3;
    }
  }
}

@media screen and (max-width: 486px) {
  .text-new-reservation {
    margin-left: 1px;
  }
}

//modifica per il tab extra della cassa
nz-tabset.beddy-custom-tabset.cash-desk-select-extra {
  .ant-tabs-nav-operations {
    display: none;
  }

  .ant-tabs-nav-wrap {
    flex: initial;

    & .ant-tabs-nav-list {
      padding-right: 3px;
    }

    &::after,
    &::before {
      display: none;
    }
  }

  .ant-tabs-nav {
    .ant-tabs-extra-content {
      float: none !important;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

//modifiche per il tabset delle properties
nz-tabset.beddy-custom-tabset.properties-tabset {
  flex: 1;
  margin: 0;

  .ant-tabs-nav-operations {
    display: none;
  }

  .ant-tabs-nav-wrap {
    flex: initial;

    & .ant-tabs-nav-list {
      padding-right: 3px;
    }

    &::after,
    &::before {
      display: none;
    }
  }

  .ant-tabs-tab-btn {
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-tabs-nav {
    margin: 0;

    .ant-tabs-extra-content {
      float: none !important;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
    }

    &::before {
      display: none;
    }
  }
}
