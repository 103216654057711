.widget {
  &--without-header {
    .ant-card-head {
      display: none;
    }
  }
  .ant-tabs {
    margin: 4px;
  }

  .ant-tabs-nav {
    margin: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-card-head {
    padding: 0 10px;
    margin-bottom: 0;
  }

  .ant-card-head .ant-tabs {
    margin: 0;
  }

  .widget-list-actions {
    display: flex;
    align-items: center;
    color: #334455;

    & > * {
      margin-left: 10px;
      color: #334455;
    }
  }

  .widget-list {
    height: 230px;

    & .ant-list-item {
      padding: 22px 15px;

      &:nth-child(odd) {
        background-color: #f8f9fb;
      }
    }

    & .ant-list-item-meta-title {
      font-size: 14px;
    }

    & .ant-list-item-action {
      & .fa-ellipsis-v {
        font-size: 18px;
        color: #334455;
        transition: all 0.3s;
        margin-top: 3px;
        margin-left: 10px;

        &:hover {
          transform: scale(1.2);
          color: #334455;
        }
      }
    }

    & .flex-description .ant-list-item-meta-description {
      display: flex;
      flex-direction: column;

      & > span {
        line-height: 1.5;
      }
    }
  }
}
