.filters-row__box__selects__select {
  .ant-select-selection-search {
    width: 20px !important;
  }
}

.stats-pass-charges-select {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
  }
}
