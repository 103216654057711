.add-bill-modal {
  &__accommodations {
    &-search-type {
      color: #fff;

      & nz-select-arrow {
        color: #fff;
      }
    }

    &-select {
      &.ant-select-single .ant-select-selector {
        height: 100px;

        & .ant-select-selection-search-input {
          height: 100px;
        }

        & .ant-select-selection-placeholder {
          line-height: 100px;
        }
      }

      & .ant-select-item-option {
        padding: 0;
      }
    }
  }
}
