@use '../grid-breakpoints';
@use '../customized-scrollbar';
@use '../colors';

.by-attachment-viewer {
  .ant-select-selector,
  .ant-input-number-input {
    padding: 0 11px !important;
    box-sizing: border-box !important;
  }
  .anticon.ant-input-number-handler-up-inner.anticon-up {
    margin-top: -5px;
  }
  .ant-select-arrow {
    margin-top: -6px !important;
  }

  #viewerContainer {
    @include customized-scrollbar.customizedScrollbar();
  }
}
.by-attachment-viewer-tool-bar--mobile-item {
  display: none;
}

@include grid-breakpoints.grid-respond-to('before-medium') {
  .by-attachment-viewer-tool-bar--desktop-item {
    display: none;
  }
  .by-attachment-viewer-tool-bar--mobile-item {
    display: inline-block;
  }
}

@include grid-breakpoints.grid-respond-to('before-large') {
  by-attachment-viewer-paging-area {
    display: none;
  }
  .by-attachment-viewer-zoom-toolbar {
    .by-attachment-viewer-tool-bar--desktop-item {
      display: none;
    }
    .by-attachment-viewer-tool-bar--mobile-item {
      display: inline-block;
    }
  }
}
