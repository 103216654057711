.note-tabset {
  .ant-tabs-nav-add {
    float: right;
  }
}

.ant-tabs.note-tabset > .ant-tabs-nav .ant-tabs-nav-list {
  display: block;

  width: 100%;
}
