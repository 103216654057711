@import './variables';

.tableau-modal-new:not(.modal_selection) {
  background-color: transparent !important;

  & .page-header {
    background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
    padding: 0;
    margin: 0;
    border-bottom: none;
  }

  & .ant-modal-content {
    overflow: hidden;
  }

  & .ant-modal-header {
    padding: 7px 18px 0px 18px;
    background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
    border-bottom: none;
    font-weight: bold;

    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: rgba(map-get($tableau-modal-colors, 'grey-light'), 0.3);
    }
  }

  & .ant-modal-close-x {
    line-height: 35px;
    width: 36px;
    height: 36px;
    color: map-get($tableau-modal-colors, 'white');
  }

  & .ant-modal-title,
  & .ant-modal-body {
    color: map-get($tableau-modal-colors, 'white');
  }

  & .ant-modal-title {
    padding: 7px 0;
  }

  & .ant-btn {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  & .ant-modal-footer,
  .tableau-modal-reservations-detail .ant-modal-title {
    display: none;
  }

  & .ant-modal-body {
    padding: 0;
  }

  & .ant-calendar-picker {
    width: 120px !important;
  }

  & .ant-form-item-label {
    line-height: unset !important;
    padding: unset;
  }
  & .ant-form-item-label label {
    margin: 0px !important;
  }

  & .ant-form-item {
    margin: 0px !important;
  }

  & .ant-form-item-control {
    line-height: 35px;
    text-align: right;
  }

  & .date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 25px 30px;

    &__number-night {
      align-items: center;
    }

    & > div {
      display: flex;
      flex-direction: column;
    }

    &--label {
      color: map-get($tableau-modal-colors, 'blue-dark');
    }

    &--value {
      font-size: 16px;
      font-weight: bold;
      color: map-get($tableau-modal-colors, 'white');
    }

    & > i {
      font-size: 12px;
      color: map-get($tableau-modal-colors, 'white');
    }
  }

  & input,
  & nz-select,
  & textarea & .input-group-text-custom {
    @include tableauFormFont;
  }

  & .ng-trigger-helpMotion {
    @include tableauFormExplainFont;
  }

  .new_reservation__step1 {
    .ant-picker {
      padding: 0;
    }

    & .custom-style {
      width: 92px !important;
      background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
      border: 0;

      input {
        background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
        color: map-get($tableau-modal-colors, 'white') !important;
        border: none;
        padding: 0;
        font-size: 16px !important;
        font-weight: bold;
        height: 20px;
        cursor: pointer;
      }

      i {
        display: none;
      }
    }
  }

  & .new_note {
    & .ant-picker {
      width: 92px !important;
      background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
      border: 0;
      padding: 0;

      & input {
        background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
        color: map-get($tableau-modal-colors, 'white') !important;
        border: none;
        padding: 0;
        font-size: 16px !important;
        font-weight: bold;
        height: 20px;
        cursor: pointer;
      }

      & i {
        display: none;
      }
    }
  }
}

// remove header horizontal divider in new reservation modal step 2
.new_reservation__step2 {
  & .ant-modal-header {
    margin-bottom: -1px;
  }

  & .ant-modal-header::after {
    display: none !important;
  }
}

// remove header horizontal divider in selection modal
.modal_selection {
  & .ant-modal-header {
    margin-bottom: -1px;
    padding: 15px 24px 5px 16px;
  }

  & .ant-modal-header::after {
    display: none !important;
  }
}

.tableau-modal-new {
  &.modal_selection .ant-modal-body {
    padding: 15px;
  }

  & .ant-modal-close {
    cursor: pointer;
    padding-right: 5px;

    &::after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f00d';
      font-size: 16px;
      position: absolute;
    }

    &-icon {
      display: none;
    }
  }

  &:not(.modal_selection) .ant-modal-close::after {
    color: map-get($tableau-modal-colors, 'white');
    top: 50%;
    transform: translateY(-50%);
  }

  &.modal_selection .ant-modal-close::after {
    color: rgba(map-get($tableau-modal-colors, 'black'), 0.65);
    top: 25%;
    right: 17%;
    transform: translateY(-25%) translateX(-17%);
  }
}
