$w-start: 5;
$w-end: 100;
$w-step: 5;
$unit: '%';

// Definire la base della classe
$base-class: 'by-w';

// Loop per generare le classi con width dinamico

@while $w-start <= $w-end {
  $class-name: '#{$base-class}-#{$w-start}';
  .#{$class-name} {
    width: #{$w-start}#{$unit} !important;
  }
  $w-start: $w-start + $w-step;
}

.by-w-33 {
  width: 33% !important;
}

.by-w-190 {
  width: 190px !important;
}

.by-w-600 {
  width: 600px;
}
