$gridBreakpoints: (
  'extra-small': 'max-width: 575px',
  'small': 'min-width: 576px',
  'medium': 'min-width: 768px',
  'large': 'min-width: 992px',
  'xLarge:': 'min-width: 1200px',
  'xxLarge': 'min-width: 1600px',
  'before-small': 'max-width:  575px',
  'before-medium': 'max-width:  767px',
  'before-large': 'max-width:  991px',
  'before-xLarge': 'max-width: 1199px',
  'before-xxLarge': 'max-width: 1599px',
);

@mixin grid-respond-to($name) {
  @if map-has-key($gridBreakpoints, $name) {
    $mediaQuery: map-get($gridBreakpoints, $name);
    @media (#{$mediaQuery}) {
      @content;
    }
  } @else {
    @warn "no match found in breakpoint map";
  }
}
