@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';

/*
--------------------------------- FIX BASTARDI CINISI ----------------------------------
*/

.ant-select-disabled .ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

/*
--------------------------------- FINE FIX BASTARDI CINISI ----------------------------------
*/

@text-color: #334455;
@label-color: #334455;
@font-size-base: 13px;
@line-height-base: 1.5;

// Input
@border-radius-base: 4px;
@border-width-base: 2px;
@border-color-base: #c9c9d2;
//@input-height-sm: 26px;

// Buttons
@btn-border-radius-base: 4px;
@btn-height-base: 32px;

.ant-btn {
  border-width: 1px;
}

// Collapse background
@collapse-header-bg: #fff;

//Input number height fix
.@{input-number-prefix-cls} {
  &-input {
    height: @input-height-base - 4px;
  }

  &-lg {
    input {
      height: @input-height-lg;
    }
  }

  &-sm {
    input {
      height: @input-height-sm - 4px;
    }
  }
}

// Tabs
@tabs-horizontal-padding: 12px 16px;

// Visto insieme a Seby Maesano: Infattibile diversament
nz-input-group {
  .@{ant-prefix}-select-single:not(.@{ant-prefix}-select-customize-input)
    .@{ant-prefix}-select-selector {
    height: 32px;
  }
}

// Multi select
@select-multiple-item-height: 22px;

//Pick
.ant-picker {
  padding: 4.5px 11px 4.5px;
}

// Tabs border width override
@border-width-tabs: 1px;

.@{ant-prefix}-tabs-bar {
  border-bottom-width: @border-width-tabs;
}

.@{ant-prefix}-tabs.@{ant-prefix}-tabs-card
  .@{ant-prefix}-tabs-card-bar
  .@{ant-prefix}-tabs-tab {
  border-width: @border-width-tabs;
}

// Tabs button "new" line height override
.@{ant-prefix}-tabs-extra-content .@{ant-prefix}-tabs-new-tab {
  line-height: @line-height-base;
}

// Table columns
.@{ant-prefix}-col {
  min-height: unset;
}

// TABLE
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: inherit;
}

// FORM ITEM
.ant-form-item-label {
  line-height: 20px;
}

// Card
@card-head-height: 44px;

// Checkbox
@checkbox-border-width: 1px;

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.ant-select-dropdown {
  border-radius: 4px;
  padding: 0 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: auto;
}

.ant-select-selection-item {
  float: left;
  max-width: 100%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 22px;
}

.ant-select-arrow {
  right: 10px;
  top: 50%;
  width: auto;
  height: auto;
}

.ant-btn > i,
.ant-btn > span {
  display: inline;
}

.ant-btn-icon-only > * {
  font-size: 14px;
}

.ant-btn-icon-only > i {
  top: 0.5px;
  position: relative;
}

.ant-btn-icon-only.ant-btn-sm:not(.ant-btn-circle) {
  padding: 0 7px;
  width: auto;
}

.ant-btn-sm {
  border-radius: 4px;
}

nz-tabset[nzlinkrouter] .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0;
}

nz-tabset[nzlinkrouter] .ant-tabs-nav .ant-tabs-tab a {
  padding: 12px 16px;
}

.ant-tabs-nav .ant-tabs-tab .tab-title {
  padding: 0px 16px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 1px;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}

.ant-select-multiple .ant-select-selection-item-disabled {
  background: #fafafa;
  border: 1px solid #e8e8e8;
  height: auto;
  margin: inherit;
  display: inline-block;
}

.ant-select-multiple .ant-select-selector {
  border-radius: 4px;
}

.ant-select-item {
  padding: 3px 10px;
}

.search-box {
  .ant-input-affix-wrapper {
    padding: 0;
  }
}

.ant-input-affix-wrapper {
  input.ant-input {
    border: 0 none !important;
  }

  .ant-input-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    color: #334455;
    line-height: 0;
    transform: translateY(-50%);
    right: 12px;
  }
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 15px;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 1px;
}

.ant-picker {
  padding: 4.5px 11px 4px;

  &.ant-picker-small {
    padding: 0.5px 7px 0.5px;
  }
}

.ant-picker-range {
  .ant-picker-input {
    input {
      text-align: center;
      color: #949494;
    }
  }
}

.ant-tabs.ant-tabs-card
  .ant-tabs-card-bar
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
  height: 40px;
  padding: 0 16px;
  line-height: 38px;
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.ant-table {
  background: none;

  .ant-table-row-expand-icon {
    border: 1px solid #e8e8e8;
  }
}

.ant-input:focus,
.ant-input-focused {
  border-color: #40a9ff !important;
}

.ant-radio-button-wrapper {
  padding: 0 5px;
  border: 1px solid #d9d9d9;

  &:first-child {
    border-left: 1px solid #d9d9d9;
  }
}

// CALENDAR

.ant-calendar-range .ant-calendar-footer-extra {
  width: 100%;
}

// COLLAPSE
.ant-collapse {
  overflow: hidden;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: @text-color;
  cursor: initial;
}

.ant-collapse.reverse-header-colors
  > .ant-collapse-item
  > .ant-collapse-header {
  background-color: @text-color;
  color: #fff;
}

.ant-collapse.no-content-padding
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
}

.ant-menu-inline-collapsed {
  width: initial;
}

.ant-form-item-label {
  text-align: left;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 2px 0px;
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: none;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: inherit;
}

.form-control-flex-children {
  .ant-form-item-control-input-content {
    display: flex;
  }
}

.ant-form-inline {
  display: inline-flex;
}

.ant-upload.ant-upload-drag {
  border: 1px dashed #d9d9d9;
}

.ant-collapse:not(.ant-collapse-ghost) {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;

  > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
  }
}

.ant-card-bordered {
  border: 1px solid #e8e8e8;
}

.ant-alert-info {
  border-width: 1px;
}

.ant-table-row-expand-icon::before {
  background: none;
}

.ant-table-row-expand-icon::after {
  content: '+';
  left: 4px;
  top: -1px;
  background: none;
}

.ant-table-row-expand-icon-expanded::after {
  content: '-';
  margin-left: 3px;
  background: none;
  transform: none;
  left: 3px;
}

.ant-divider {
  background: #e8e8e8;
}

.ant-table-column-sorters {
  padding: 0;
  background: none !important;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  display: inline-block;
  font-size: 11px;
  height: 0.5em;
  line-height: 0.5em;
}

.ant-table-column-sorter-full {
  margin-top: -0.8em;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}

.ant-checkbox {
  border-spacing: 0;
}

.ant-tree-checkbox-inner {
  border-radius: 2px;
}

.ant-checkbox-inner::after {
  top: 46%;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 1px;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
}

.preset-ranges .preset-button {
  padding: 0 7px;
}

.ant-picker-calendar-header {
  justify-content: center;
}

.cdk-drag-handle:not(.ant-image-preview-img) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-tree-show-line .ant-tree-indent-unit:first-child::after {
  border: 0 none;
}

.ag-cell-wrapper {
  justify-content: center;
}

.ant-dropdown-menu-item .ant-menu-title-content > a,
.ant-dropdown-menu-submenu-title > a {
  color: @text-color;
  padding: 5px 12px;
  margin: -5px -12px;
  display: block;
}

.ant-dropdown-menu-item {
  display: block;
  align-items: unset;
}
