$body--background_color: #f1f4f9;

// Colors
$light-grey: #f7f9fa;

// Items Specifics
$topbar--height: 56px;

$sidebar--width: 230px;

// Theme overrides
$theme-colors: (
  'primary': #34495e,
  'danger': #ff4136,
);
// TinyMCE (text editor) remove logo
.mce-notification {
  display: none;
}

$theme-colors: (
  'primary': #34495e,
  'danger': #ff4136,
);

$input-group-width: (
  'number': 50px,
  'currency': 85px,
  'time': 85px,
  'select__min': 100px,
  'select-medium__max': 150px,
  'select-small__max': 150px,
);

$icon-colors: (
  'azure': #00a7ff,
  'grey': #000000a6,
);

$default-blue: #047bf8;

$crm-arrow-icon--color: #4d658f;
