.crm-summary {
  & .ant-card-head {
    padding: 0 10px !important;
  }
}

.crm-preview-card {
  color: #293144;
  font-size: 14px;
  padding: 0 12px;

  & .ant-form-item,
  & .ant-switch {
    margin: 0;
  }

  & .ant-form-item-control {
    line-height: 10px;
  }
}
