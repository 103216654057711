.guest-tab-date-picker.focused {
  .ant-input-group-addon {
    border-color: #40a9ff !important;
    border-right-width: 2px !important;
  }
}

.guest-tab-date-picker:hover {
  .ant-input-group-addon {
    border-color: #40a9ff !important;
    border-right-width: 2px !important;
  }
}
