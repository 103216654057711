.crm-new-reservation-cart-edit {
  overflow: hidden;

  & nz-table {
    padding: 15px;
  }

  & .ant-table-body > table {
    border: none;
    padding: 0;
  }

  & .ant-table-bordered .ant-table-tbody > tr > td,
  & .ant-table-bordered .ant-table-thead > tr > th {
    border-right: none;
  }

  & .ant-card-body {
    padding: 0 !important;
  }

  & .cart-item {
    &__header {
      background-color: #2a3144;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 30px !important;

      & * {
        color: #fff;
      }

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--action-box {
        padding: 10px 0;
        border-left: 1px solid #ffffff52;
      }

      &--delete {
        color: #d4d7dc;
        padding: 0 25px;

        &:hover {
          color: #fff;
        }
      }

      &--text {
        width: 100%;
        padding-right: 30px;
      }

      &--general-infos {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & nz-divider {
          background-color: #e8e8e87a;
        }
      }
    }
  }
}
