.by-tabset-left-extra-content {
  .ant-tabs-nav-wrap {
    flex: none !important;
    margin-right: 10px;
  }

  nz-tab-nav-operation {
    display: none !important;
  }
}
