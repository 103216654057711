$addAccommodationBtnWidth: 32px;

.accommodation-select {
  .ant-input-group {
    display: flex;
  }
  .ant-select {
    width: calc(100% - #{$addAccommodationBtnWidth});
  }
  .ant-input-group-addon {
    vertical-align: inherit;
  }

  .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
