.bottom-sheet {
  &__mask {
    background-color: #0000008c;
    opacity: 1;
    animation: spawn 0.3s ease-in-out;
  }
}

@keyframes spawn {
  from {
    opacity: 0;
  }
}
