@import '../colors';

.bulk-table {
  .tr-room td {
    font-weight: bold;
    columns: #000000;
    background: darken(#f8f9fb, 5%) !important;
  }

  .tr-name-property {
    background: #e1e2e4 !important;
    height: auto;
    padding: 0px 0px;
    font-weight: bold;
    padding-left: 4px;
  }
  .tr-name-property i {
    margin-right: 5px;
  }

  .tr-name-property td {
    padding: 5px;
    background: #e1e2e4 !important;
  }

  .td-align:nth-child(3) .first-clone {
    display: inline-block !important;
  }

  .td-align .first-clone {
    display: none !important;
    margin-left: 10px;
  }

  .td-align i {
    margin-left: 10px;
  }

  .td-align td:first-child {
    text-align: left;
  }

  .td-align .anticon-arrow-down .td-align td {
    text-align: center;
  }
}
