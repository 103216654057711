ngx-extended-pdf-viewer.ngx-extended-pdf-viewer-custom {
  .loadingInProgress.body,
  #toolbarContainer {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  .pdfViewer {
    .page {
      margin: 8px auto -8px !important;
    }
  }
}
