#searchSelect.search_select--desktop .ant-select-selection-placeholder {
  line-height: 50px;
}

#searchSelect.search_select--mobile .ant-select-selection-placeholder {
  line-height: 41px;
}

#searchGroupSelect {
  border: none;
  box-shadow: none;
}

#searchSelect {
  height: 100%;
  width: 100%;
  border: 0;
  box-shadow: none;

  .ant-select-selector,
  .ant-select-selection {
    height: 100%;
    border: 0 !important;
    box-shadow: none !important;
  }

  .ant-select-selection-search-input {
    height: 100%;
  }

  .ant-select-selection__rendered,
  .ant-select-search {
    width: 100%;
    display: block !important;
  }

  .ant-select-search {
    padding: 0;
  }

  .option-link  {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.searchSelectDropdown {
  .cdk-virtual-scroll-viewport {
    height: unset !important;
  }
}
