.reservation-calendar {
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-picker {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }
}
