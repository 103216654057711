.by-responsive-tabset {
  & .ant-tabs-extra-content {
    float: none !important;
    flex: auto;
    max-width: 500px;
  }

  & .ant-tabs-nav-container {
    flex: auto;
  }

  & .ant-tabs-top-bar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
