.input-group-fixed {
  .ant-input-wrapper.ant-input-group {
    .ant-input-group-addon {
      padding: 0;
      border: 0;
    }

    nz-select-top-control {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
    }
  }
}
