.rateplan-option-collapsible {
  padding: 15px 12px;
  background: #f8f8fa;
  border-top: 1px solid #c0c8d5;
  width: 100%;

  &:hover {
    background: #e5f1fa;
  }
}
