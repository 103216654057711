@use './../colors';

.by-zdlake-filters__properties-tabs.stat-properties-tabset {
  .ant-tabs-tab.ant-tabs-tab-active {
    background: map-get(colors.$colors, 'stats-tabset-background') !important;
    .ant-tabs-tab-btn {
      color: #ffffff !important;
    }
  }
}
