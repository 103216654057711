@import '../colors';

$green: #00d82e;

.document-list-table-responsive {
  .ant-table-content > table {
    padding-top: 0;
  }
  & .ant-table-tbody > tr > td {
    padding: 20px 2px;
    border-left: none;
  }

  & .ant-table-thead > tr > th {
    display: none;
    border-left: none;
  }

  .first-row {
    display: flex;
    justify-content: space-between;
    &__first-box {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding-right: 5px;

      &__document-info {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
      }
    }

    &__second-box {
      display: flex;
      align-items: center;
      &__total-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .first-expanded-row {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    &__box {
      padding: 4px 0;
    }

    &__box:not(:last-child) {
      padding-bottom: 10px;
    }
  }

  .second-expanded-row {
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__box {
      display: flex;
      flex-direction: column;
    }
  }

  .third-expanded-row {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    &__box {
      display: flex;
    }

    &__box:not(:last-child) {
      padding-bottom: 10px;
    }
  }

  .fourth-expanded-row {
    display: flex;
    justify-content: space-around;
  }

  .font-14-g {
    font-size: 14px;
    font-weight: 600 !important;
    color: #2b3949;
  }

  .fad.fa-check-circle:before {
    color: #ffff;
  }

  .fad.fa-check-circle:after {
    color: map-get($colors, 'green');
    opacity: 1;
  }

  .text-align-end {
    text-align: end;
  }

  .payment-icon {
    padding: 0 10px;
    font-size: 20px;

    &--suspended {
      color: #ee9200;
    }

    &--expired {
      color: map-get($colors, 'red');
    }
  }

  .color {
    &--green {
      color: $green;
    }
  }

  .pr-4px {
    padding-right: 4px;
  }

  .pl-4px {
    padding-right: 4px;
  }

  .text-align-center {
    text-align: center;
  }

  .suspended {
    color: #ee9200;
  }

  .expired {
    color: map-get($colors, 'red');
  }

  .paid {
    color: map-get($colors, 'green');
  }
}
