@use '../customized-scrollbar';
@use '../colors';

.stats-debug-table {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .ant-table-thead > tr:last-child > th:last-child {
    border-left: none;
  }

  .ant-table-header.nz-table-hide-scrollbar {
    overflow: hidden !important;
  }

  cdk-virtual-scroll-viewport {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'grey-light'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'grey-light')
    );
  }

  cdk-virtual-scroll-viewport:hover {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'grey-light'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'grey-dark')
    );
  }
}
