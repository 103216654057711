$tableau-modal-colors: (
  'white': #ffffff,
  'black': #000000,
  'grey-light': #dbdbe4,
  'grey-dark': #5a5353,
  'blue-dark': #23282f,
  'red-dark': #a00606,
  'newReservation-bg': #62cb31,
  'newEstimate-bg': #e99400,
  'newNote-bg': #5faeff,
  'newOption-bg': #dfad00,
  'newClosePeriod-bg': #c67ac0,
  'newDeleteClosePeriod-bg': #6cbf45,
  'modalContent-bg': #f3f3f5,
  'modalHeader-bg': #047bf8,
);

$new-reservation-form-colors: (
  'row-dark-bg': #f7f9fa,
  'explain-font': #f5222d,
  'form-font': #151313,
);

$font-sizes: (
  'form': 13px,
);

@mixin tableauFormExplainFont {
  font-size: map-get($font-sizes, 'form');
  color: map-get($new-reservation-form-colors, 'explain-font');

  &::first-letter {
    text-transform: uppercase;
  }
}

@mixin tableauFormFont {
  font-size: map-get($font-sizes, 'form');
  color: map-get($new-reservation-form-colors, 'form-font');
}
