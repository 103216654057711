.guest-tab-date-picker {
  .ant-picker {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .ant-picker-focused {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }

    .ant-picker-input > input {
      text-align: right;
    }

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-input-group-addon {
    background: #fff;
  }
}
