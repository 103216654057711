.note-collapse {
  .ant-collapse-content {
    border-top: 0;
  }
}
.note-collapse.ant-collapse {
  border: 0;
  background-color: #fff;
}

.note-collapse.ant-collapse.expanded {
  border: 0;
  border-left: 2px solid #047bf8;
}
