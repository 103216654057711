@use './colors';

@mixin customizedScrollbar(
  $barColor: map-get(colors.$colors, 'grey-dark'),
  $bgColor: #fff,
  $barColorHover: map-get(colors.$colors, 'grey-dark')
) {
  /* Works on Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: $barColor $bgColor;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $bgColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $barColor;
    border-radius: 20px;
    border: 3px solid $bgColor;

    &:hover {
      background-color: $barColorHover;
    }
  }
}
