$border-color: #dbdbe4;

.reservation-tag {
  &__dropdown ul {
    display: flex;
    padding: unset;
    width: max-content;
    max-width: 294px;
    flex-wrap: wrap;
  }
}

.tagDropdownItemLargeVersion {
  display: flex;
  align-items: center;

  & span {
    margin-left: 5px;
  }
}

.tag-box {
  display: flex;
  align-items: center;
}

.tag-label {
  font-size: 12px;
  margin-left: 4px;
}

.tag-div {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid $border-color;

  &.tag-div-big {
    width: 35px;
    height: 35px;
  }

  &__not-specified {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      background: $border-color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skew(45deg);
      height: 12px;
      width: 2px;
    }

    &::before {
      position: absolute;
      content: '';
      background: $border-color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skew(-45deg);
      height: 12px;
      width: 2px;
    }
  }
}
