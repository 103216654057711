.user-logo-text {
  background-color: map-get($colors, 'message-logo-bg');
  color: map-get($colors, 'white');
  font-size: 16px;
  height: 45px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.by-page-header-reservation-messages .page-header-component {
  padding-top: 0px;
  margin-bottom: 0px;
  .title {
    padding-top: 0px;
  }
}

.reservation-messages-group-border-none .ant-select .ant-select-selector {
  border: 0 !important;
  color: #334455;
}

.by-message-filters .ant-collapse-content .content-box .by-message-filters {
  align-items: flex-end !important;
}

.reservation-message {
  .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 0px !important;
    border-bottom: 1px solid #c9c9d2 !important;
    border-radius: 0px !important;
    padding: 8px;
    font-size: 15px;
  }

  .ant-select-selection-item {
    border: 1px solid #c9c9d2 !important;
    border-radius: 15px !important;
    padding: 2px 12px 2px 12px;
    background: none !important;
    font-weight: 500;
    height: 28px !important;
    font-size: 15px;
  }

  .ant-input {
    padding: 5px 0px 10px 8px;
    border-bottom: 1px solid #c9c9d2 !important;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px !important;
    font-size: 13px;
  }

  .ant-mentions:focus-within {
    border: 0px !important;
    box-shadow: none;
    border: 0px 0px 1px 0px solid #40a9ff;
  }

  .by-text-editor {
    .ql-toolbar.ql-snow {
      border: 0px;
      border-bottom: 1px solid #ccc;
      padding: 20px 0px 10px 0px;
    }

    .ql-container.ql-snow {
      border: 0px;
      font-size: 13px;

      .ql-editor {
        min-height: 150px;
      }

      .ql-editor.ql-blank::before {
        color: #c9c9d2;
        font-style: normal;
      }
    }
    quill-editor {
      min-height: 150px !important;
    }
  }

  .by-reservation-messages-custom-button {
    .ant-select-selection-item {
      color: white !important;
    }
  }

  .by-messages-viewer-toolbar {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none;
    }
  }
}

.by-reservation-details-messages {
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    box-shadow: none;
    border-bottom: 1px solid red !important;
  }

  .ant-mentions.ant-mentions-status-error:not(.ant-mentions-disabled):not(
      .ant-mentions-borderless
    ).ant-mentions:focus-within {
    box-shadow: none;
  }
}
