#housekeeping-page {
  .list-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &__item {
      width: calc(20% - 20px);
      min-width: 200px;
      margin: 10px;
    }

    &__body-item {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      min-height: 135px;
    }

    &__bottom-item {
      background-color: #f1f4f9a1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      & .left-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .accommodation {
    display: flex;
    flex-direction: column;

    &__label {
      font-weight: 700;
    }
  }

  .double-border {
    border: 2px solid;
    background-color: #fff;

    &.dirty {
      border-color: #c0392b;
    }

    &.clean {
      border-color: #62cb31;
    }
    &.disabled {
      border-color: #676767;
    }
  }

  .fa-sign-in-alt {
    font-size: 16px;
    cursor: pointer;

    &.stay {
      transform: rotate(90deg);
    }
  }

  .fa-comment-dots {
    font-size: 16px;
    color: #047bf8;

    &.no-note {
      color: inherit;
    }
  }

  .fa-person-sign {
    font-size: 16px;
    color: #047bf8;

    &.no-note {
      color: inherit;
    }
  }

  .view-type-select-box .ant-radio-button-checked + span {
    color: #fff;
  }

  // MEDIA QUERIES

  @media only screen and (max-width: 1400px) {
    .list-box__item {
      width: calc(25% - 20px);
    }
  }

  @media only screen and (max-width: 1200px) {
    .list-box__item {
      width: calc(33.33333333% - 20px);
    }
  }

  @media only screen and (max-width: 800px) {
    .list-box__item {
      width: calc(50% - 20px);
    }
  }

  @media only screen and (max-width: 550px) {
    .list-box__item {
      width: 100%;
    }
  }
}

#housekeeping-property-table {
  & .ant-table-thead > tr > th {
    vertical-align: middle;
    padding: 8px;
    text-align: center;
    line-height: 1;

    & * {
      font-size: 12px;
    }
  }

  & .ant-table-body > table {
    padding-top: 0;
  }

  & .ant-table-tbody > tr > td {
    padding: 10px 5px;
  }

  & .ant-table-thead > tr > th.subth {
    border-top: 1px solid #e0e3ea;

    & * {
      font-size: 11px;
    }
  }
}
