@use '../../scss/colors' as colors;
@use './document-payment-status' as paymentStatusColor;

$colors: colors.$colors;

$rowBgGrey: #f1f4f9;
$rowBgWhite: #ffffff;

$rowBgGreyEven: #f9fbfd;
$rowBgWhiteEven: #fdfdfe;
$rowBgHover: #e6f7ff;

$labelGray: map-get($colors, 'label-gray');

.generic-list-table {
  .ant-table-content tr {
    &:nth-child(odd) {
      background: $rowBgWhite;

      &:hover > td {
        background: $rowBgHover;
      }
    }

    &:nth-child(even):not(.tr-expand-row) {
      background: $rowBgWhiteEven;

      &:hover > td {
        background: $rowBgHover;
      }
    }

    &.bg--grey {
      background: $rowBgGrey;
    }
  }

  &.background--grey {
    .ant-table-content tr:nth-child(odd) {
      background: $rowBgGrey;
      transition: background 0.3s;
    }

    .ant-table-content tr:nth-child(even) {
      background: $rowBgGreyEven;
      transition: background 0.3s;
    }

    .ant-table-content tr:nth-child(odd) td.ant-table-cell-fix-right {
      background: $rowBgGrey !important;
    }

    .ant-table-content tr:nth-child(even) td.ant-table-cell-fix-right {
      background: $rowBgGreyEven !important;
    }

    .ant-table-content tr:hover,
    .ant-table-content tr:hover td,
    .ant-table-content tr:hover td.ant-table-cell-fix-right {
      background: #e6f7ff !important;
    }

    .ant-table-placeholder {
      display: none;
    }
  }

  .ant-table-tbody > tr td.empty-td {
    border-bottom: 2px solid #e9eaee;
    width: 26px !important;
  }

  .ant-table-tbody > tr:not(.ant-table-expanded-row) td:not(.empty-td) {
    vertical-align: top;
    padding: 40px 25px;
    border-top: 0 none;
    border-right: 0 none;
    border-left: 0 none;
    border-bottom: 2px solid #e9eaee;

    &.border-right {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 15px;
        right: 0;
        height: calc(100% - 30px);
        width: 1px;
        border-left: 1px solid #e9eaee;
      }
    }
  }

  tr:hover td:last-child {
    background-image: none;
  }

  &__container-data > div {
    padding: 0 5px;
  }

  &__container-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 20px;
  }

  &__container-data-no-pd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 20px;
    padding: 0;
  }

  &__info-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
  }

  &__info-container-start {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    align-items: center;
  }

  &__label,
  &__data-text,
  &__data-text-g {
    &.color--green {
      color: map-get($colors, 'green') !important;

      & > * {
        color: inherit;
      }
    }

    &.color--red {
      color: paymentStatusColor.$unpaidRed !important;

      & > * {
        color: inherit;
      }
    }
  }

  &__label {
    font-weight: 600 !important;
    font-style: italic;
    color: $labelGray;
    font-size: 13px !important;

    &--small {
      font-style: italic;
      color: $labelGray;
      font-size: 13px !important;
    }
  }

  &__label--normal {
    color: $labelGray;
    font-size: 13px !important;
  }

  &__contacts {
    color: $labelGray;
    margin-top: 2px;
    white-space: nowrap;
    display: flex;
    gap: 5px;
    align-items: baseline;
  }

  &__sdi-icon {
    font-size: 16px;
    margin-right: 15px;
  }

  &__data-text-g {
    font-weight: 800 !important;
  }

  &__data-16px-g {
    font-size: 16px !important;
    color: #2b3949;
    font-weight: 600 !important;
  }

  &__icon-link {
    font-size: 18px;
  }

  &__paperclip {
    font-weight: 200;
    font-size: 20px !important;
    display: flex;
    margin-left: 5px;
  }

  &__font-15 {
    font-size: 15px;
  }

  &__font-14-g {
    font-size: 14px;
    font-weight: 600 !important;
    color: #2b3949;
    white-space: nowrap;
  }

  &__font-14 {
    font-size: 14px;
  }

  &__font-13 {
    font-size: 13px;
  }

  &__font-13-g {
    font-size: 13px;
    color: #2b3949;
    font-weight: 600 !important;
  }

  &__status-payment {
    display: flex;
    align-items: baseline;
    i {
      font-size: 18px;

      &.paid {
        color: paymentStatusColor.$paidGreen;
      }

      &.suspended {
        color: paymentStatusColor.$suspendedOrange;
      }

      &.expired {
        color: paymentStatusColor.$unpaidRed;
      }
    }

    & .paid {
      color: paymentStatusColor.$paidGreen;
    }

    & .suspended {
      color: paymentStatusColor.$suspendedOrange;
    }

    & .expired {
      color: paymentStatusColor.$unpaidRed;
    }
  }

  &__paid-total {
    font-size: 12px;
    font-weight: 600;
    width: 150px;
  }

  &__bar-payment {
    line-height: 1px;
    margin-top: 10px;
  }

  & .ellipsis-actions-td {
    i {
      color: #198ffd !important;
      line-height: 40px;
    }
  }

  .ant-table-expanded-row td {
    padding: 0px;
    border: none;
  }

  .expand-td {
    padding: 40px 0px !important;
    text-align: right;
    width: 70px;
    height: 60px;

    .icon-expand {
      color: map-get($colors, 'grey-dark');
      font-weight: bold;
      font-size: 18px;
      transition: all 0.3s;
    }
  }

  .status-circle {
    font-size: 18px;
  }
}

.price-quotation-accommodations-table {
  .ant-table {
    border-radius: 0;
  }
  .ant-table-footer {
    background: #f1f9ff;
    border: 0;
    border-bottom: 2px solid #83c6fe;
    border-radius: 0;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  tr.last-accommodation > td {
    border-bottom: 1px solid #83c6fe !important;
  }

  tr.total-row > td {
    background: #f1f9ff !important;
  }

  .ant-table-tbody > tr:last-of-type > td {
    border-bottom: 2px solid #83c6fe !important;
  }
}

.table-td-bolder {
  .ant-table-tbody > tr > td {
    font-weight: bold;
  }
}

.table-borderless {
  .nz-table-out-bordered > .ant-table-container {
    border: 0;
  }

  .ant-table-thead > tr > th {
    padding: 0 11px !important;
    border-left: 0;
  }
  .ant-table-tbody > tr > td {
    border-left: 0 !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }

  .ant-table-thead > tr > th {
    text-align: left;
  }
}

.price-quotation-accommodations-card {
  .ant-card-head {
    border-bottom: 0;
  }
}

.icon-container {
  align-items: center;
  display: flex;
  padding: 0;
}

.recap-table {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid map-get($colors, 'grey') !important;

  table {
    padding-top: 0px !important;
    thead {
      height: 40px;
      th {
        border-radius: 0 !important;
        background-color: map-get($colors, 'ligth-cyan');
      }
    }
  }
  tr > td {
    border-bottom: 0 !important;
  }
}
