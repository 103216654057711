.ant-steps-item-finish .ant-steps-icon-dot {
  background: #4c84ff !important;
  color: #ffff !important;
}

.ant-steps-item-process .ant-steps-icon-dot {
  background: #ffff !important;
  color: #4c84ff !important;
}

.ant-steps-icon-dot {
  width: 24px !important;
  height: 24px !important;
}

.ant-steps-item-process .ant-steps-icon-dot {
  width: 24px !important;
  height: 24px !important;
  box-shadow: 2px 4px 30px -5px rgba(0, 0, 0, 0.41);
}

.ant-steps-item-finish .ant-steps-icon-dot::after {
  content: '\f00c' !important;
  background: transparent !important;
}

.ant-steps-item-process .ant-steps-icon-dot::after {
  content: '' !important;
  display: inline-block !important;
  width: 8px !important;
  height: 8px !important;
  top: 8px !important;
  left: 8px !important;
  border-radius: 7.5px !important;
  background-color: #4c84ff !important;
}

.ant-steps-item-process .ant-steps-item-title {
  color: #3498db !important;
}

.ant-steps-item-wait .ant-steps-icon-dot {
  background: #ffff !important;
  color: #4c84ff !important;
  border: 2px solid #dfe2e9;
}

.ant-steps-icon-dot::after {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 7px !important;
  font-weight: 900;
  font-size: 12px;
  left: 0px !important;
  font-family: 'Font Awesome 5 Pro' !important;
}

.ant-steps-dot .ant-steps-item-tail:after {
  height: 2px !important;
  width: calc(100% - 23px) !important;
  margin-left: 21px !important;
  margin-top: 8px !important;
}

.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after,
.ant-steps-item-finish > .ant-steps-item-tail:after {
  background-color: #4c84ff;
}

.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title:after,
.ant-steps-item-process > .ant-steps-item-tail:after {
  background-color: #dfe2e9 !important;
}

.ant-steps-dot .ant-steps-item-content {
  width: 70px !important;
  margin-left: 44px;
}

.ant-steps {
  padding-right: 33px;
}

.ant-steps-item-title {
  font-size: 12px !important;
  margin-top: 21px !important;
  color: #888888 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: none;
}
