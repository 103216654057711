.labelAsCheckbox {
  & .ant-checkbox + span {
    color: #047bf8;
    padding: 1px 5px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #047bf8;
  }

  & .ant-checkbox-checked + span {
    color: #fff;
    padding: 1px 5px;
    background-color: #047bf8;
    border-radius: 3px;
  }

  & .ant-checkbox {
    display: none;
  }
}
