.price-list-list {
  .price-list-name {
    word-break: break-word;
    display: flex;
    align-items: center;
  }
  .ant-list-item-no-flex {
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-list-item-action {
    margin-left: 8px !important;
  }
}
