.balance-summary-table {
  margin: 0 25px;

  .ant-table-thead > tr > th {
    background-color: #f1f4f9;
  }

  .ant-table-content tr:nth-child(odd) {
    background-color: #eaeff5;
    &.ant-table-placeholder {
      background: #fff;
    }
  }
  .ant-table-content tr:nth-child(even) {
    background-color: #f1f4f9;
  }

  .ant-table-content tr.totals-row {
    background-color: #d3e0f0;
  }

  .ant-table-content tr:hover {
    background-color: #e6f7ff;
  }
}
