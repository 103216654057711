.viewport-conversation-messagges {
  height: 80vh;
  width: 100%;
  @include customizedScrollbar();
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }
}

.contact-info-tooltip {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}
