.changelogs-collapse > .ant-collapse-item {
  > .ant-collapse-header {
    padding-top: 5px;
    padding-bottom: 5px;
    .ant-collapse-arrow {
      cursor: pointer;
      top: 62%;
    }
    .ant-form-item {
      margin-bottom: 0px;
      .ant-form-item-control {
        margin-bottom: 0px;
      }
    }
  }

  > .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 18px;
  }
}

.changelogs-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 15px;
  padding-bottom: 20px;
}
