@use '../grid-breakpoints' as mixin;
@use '../colors';

.by-tabset {
  nz-tabset[nzlinkrouter] .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
  }
}

.by-tabset {
  nz-tabset {
    .ant-tabs-nav {
      margin-bottom: 0;
      padding-left: 20px;
    }

    &:not(.page-margin) {
      .ant-tabs-nav {
        padding-left: 0px;
      }
    }
  }

  &__mobile {
    .ant-select-selection-search {
      border-bottom: 1px solid map-get($colors, 'tabset-border');
    }
  }

  &__extracontent {
    padding-right: 25px;
    display: flex;

    @include mixin.grid-respond-to('before-xLarge') {
      padding-right: 0;
      padding-top: 10px;
      flex-direction: column;
    }

    &__properties-select {
      margin-right: 10px;

      @include mixin.grid-respond-to('before-xLarge') {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    button {
      @include mixin.grid-respond-to('before-xLarge') {
        margin-bottom: 10px;
      }
    }
  }
}
