@use '../customized-scrollbar';
@use '../colors';

.widget-table.generic-list-table {
  .ant-table-tbody > tr:not(.ant-table-expanded-row) td:not(.empty-td) {
    padding: 0 !important;
    padding-left: 10px !important;
    height: 71px;
    vertical-align: middle;
  }

  .ant-table-tbody > tr:not(.ant-table-expanded-row):last-child {
    td {
      border-bottom: 0 !important;
    }
  }

  .ant-table-header {
    padding-top: 20px;
  }

  .nz-table-hide-scrollbar::-webkit-scrollbar {
    background-color: map-get(colors.$colors, 'white');
  }
  .ant-table-body {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'white')
    );
  }

  .ant-table-body:hover {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'grey-light'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'grey-dark')
    );
  }

  .ant-table-thead > tr > th {
    border-left: 0;
    padding-left: 10px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-tbody > tr:not(.ant-table-expanded-row) td:not(.empty-td) {
    padding: 25px;
  }
}
