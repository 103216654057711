@import '../colors';

#daily-overview-treatment-page {
  &.no-overflow #sticky .ant-table-body {
    overflow: hidden !important;
  }

  #sticky .ant-table-body {
    max-height: 100% !important;
    margin-top: 74px !important;
    overflow-y: hidden !important;
  }

  #sticky .ant-table-body > table {
    padding-top: 0 !important;
  }

  #sticky .ant-table {
    overflow: hidden !important;
  }

  #sticky .ant-table-header {
    overflow-y: hidden !important;
    position: absolute !important;
    z-index: 999 !important;
    overflow: hidden !important;
    top: 0px !important;
    width: 100% !important;
  }

  .property-column {
    vertical-align: middle;
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    color: #334455;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 10px;

    &--mobile {
      width: 100vw;
    }
  }

  .first-column {
    min-width: 310px;
    width: 310px;
    border-left: 1px solid #e4e5e7;
    display: table-cell;
    box-shadow: unset;
    border-right: 1px solid #e4e5e7;

    &--mobile {
      min-width: 100vw;
      width: 100vw;
    }
  }

  .cell-daily {
    min-width: 54px;
    width: 54px;
    min-height: 54px;
    height: 74px;
    border-left: 1px solid #e4e5e7;
    padding: 0px;
    margin: 0px;
    vertical-align: top;
    &:nth-child(1) {
      border-left: none;
    }
  }

  .cell-room {
    min-width: 54px;
    width: 54px;
    min-height: 30px;
    height: 30px;
    vertical-align: middle;
    border-left: 1px solid #e4e5e7;
    &:nth-child(1) {
      border-left: none;
    }
  }

  .span-open {
    font-size: 6px;
    text-transform: uppercase;
    border: 1px solid #8ed386;
    background: #d7f6d3;
    color: #1c6714;
    width: 25px;
    margin-right: 1px;
    text-align: center;
  }

  .span-close {
    font-size: 6px;
    text-transform: uppercase;
    border: 1px solid #d3665b;
    background: #f5d0cd;
    color: #801d13;
    width: 25px;
    text-align: center;
  }

  .span-day-name {
    text-transform: uppercase;
    font-size: 10px;
    color: #b2b1af;
    display: block;
    width: 100%;
    text-align: center;
  }

  .span-day-number {
    text-transform: uppercase;
    font-size: 18px;
    color: #334455;
    width: 100%;
    text-align: center;
  }

  .span-day-month {
    text-transform: uppercase;
    font-size: 10px;
    color: #b2b1af;
    width: 100%;
    text-align: center;
  }

  .cell-toolbox {
    min-height: 40px;
    height: 40px;
    vertical-align: middle;
    padding: 15px 0px 10px 0px;
    display: table-cell;
    background-color: #fff;
  }

  .min-stay {
    position: absolute;
    margin-top: 1px;
    right: 3px;
    font-size: 8px;
    color: #97002f;
    font-weight: bold;
  }

  .max-stay {
    position: absolute;
    margin-top: 9px;
    right: 3px;
    font-size: 8px;
    color: #2a5bbf;
    font-weight: bold;
  }

  .roundedR {
    border-radius: 0px 50px 50px 0px;
    // width: 47px;
    height: 22px;
    color: #ffffff;
    margin-right: 7px;
  }

  .roundedL {
    border-radius: 50px 0px 0px 50px;
    // width: 49px;
    height: 22px;
    color: #ffffff;
    margin-left: 7px;
  }

  .roundedB {
    border-radius: 50px 50px 50px 50px;
    // width: 40px;
    height: 22px;
    color: #ffffff;
    margin-left: 7px;
    margin-right: 7px;
  }

  .roundedN {
    width: 100%;
    height: 22px;
    color: #ffffff;
  }

  .no-availability {
    background: #ffc375 !important;
    border: 1px solid #c1692f;
  }

  .offer-expired {
    background: #f3f3f3;
    border: 1px solid #d3d3d3;
    opacity: 0.5;
    color: #000000;
    cursor: not-allowed;
  }

  .offer-expired-barred {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .offer-expired .span-availability {
    color: #000000;
  }

  .no-availability .span-availability {
    color: #c1692f;
  }

  .room-barred {
    background: #f5d0cd !important;
    border: 1px solid #9f0706;
    &.cut-off {
      background: #de84e4 !important;
      border: 1px solid #9f0706;
    }
    .span-availability {
      color: #a8261e;
    }
  }

  by-daily-overview-toolbox,
  by-daily-overview-rate-minimal,
  by-daily-overview-channel,
  by-daily-overview-accommodation,
  by-daily-overview-calendar {
    display: contents;
  }

  by-daily-overview-rate-standard {
    display: block;
  }

  by-daily-overview-treatments-accommodation {
    display: table;
    &.isMobile {
      display: grid;
    }
  }

  .roundedHover:hover {
    opacity: 0.6;
  }

  .icon-link-rule-left {
    font-weight: bold;
    color: #e7503b;
    padding-top: 3px;
    padding-right: 10px;
  }

  .daily-overview__matrix__tr:hover,
  by-daily-overview-rate-standard tr:hover {
    background: #f3f3f5;
    td {
      background: #f3f3f5;
    }
  }

  .hoverTH {
    background: #ffffff;
  }

  .daily-overview__matrix__tr,
  by-daily-overview-accommodation {
    user-select: none;
  }

  .selectedCellDrag {
    opacity: 0.4;
  }

  // .cell-rate-channel,
  // .cell-rate-open,
  // .cell-availability {
  //     cursor: pointer ;
  // }
  // .crosshair {
  //     cursor: pointer ;
  // }
  .buttonSaveDailyOverview {
    position: fixed;
    bottom: 2px;
    right: 0px;
    background: transparent;
    top: auto;
    z-index: 9999999;
    text-align: center;
    padding: 5px;
  }
  .buttonCancelDailyOverview {
    position: fixed;
    bottom: 2px;
    right: 70px;
    background: transparent;
    top: auto;
    z-index: 9999999;
    text-align: center;
    padding: 5px;
  }

  .filterSelectAllSpan {
    text-transform: capitalize;
    color: #2f9bfe;
  }

  &.daily-overview {
    margin: 0 -25px;
  }

  .borderClassEdit .ant-input-number-input,
  .borderClassEdit .ant-switch-small {
    border: 2px solid #71c860;
    & .ant-switch-handle {
      top: 0.5px;
    }
  }

  .borderClassEdit .ant-switch:after {
    top: 0px;
  }

  .borderColorError .ant-input-number-input {
    border: 1px solid #e7503b;
  }

  #daily-overview-spinning .ant-spin-nested-loading > div > .ant-spin {
    position: fixed;
    height: auto;
    width: auto;
    z-index: 99999;
    top: 30%;
    left: 61%;
  }

  #daily-overview-spinning .ant-spin-blur {
    pointer-events: unset;
    opacity: unset;
    -webkit-filter: unset;
    filter: unset;
    -webkit-filter: unset;
    filter: unset;
  }

  .button-save-rounded {
    line-height: 36px;
    padding: 0 16px;
    border-radius: 2px;
    text-transform: capitalize;
    border-radius: 50%;
    width: 60px;
    height: 55px;
    padding: 0;
    flex-shrink: 0;
    box-shadow:
      0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  .first-colmn-accommodations {
    background: #f3f3f5;
    vertical-align: middle;
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    color: #334455;
    border-bottom: 1px solid #e4e5e7;
    border-top: 3px solid #e4e5e7;
    user-select: none;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 765px) {
    .daily-overview {
      margin: 0 -5px;
    }
  }
}

#daily-overview-treatment-page.daily-overview--mobile {
  #sticky .ant-table-body {
    margin-top: 150px !important;
    min-height: 100vw;
  }
}

.disparity-animation {
  animation: disparityAnimation 2s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes disparityAnimation {
  0% {
    background-color: #fb9e53;
  }

  25% {
    background-color: #d02e00;
  }

  75% {
    background-color: #fb9e53;
  }

  100% {
    background-color: #fb9e53;
  }
}

.daily-overview-calendar {
  .first-column-calendar {
    text-transform: uppercase !important;
    color: #646466 !important;
    background: #f3f3f5 !important;
    font-size: 12px !important;
  }

  .button-scroll-calendar {
    color: #334455 !important;
    font-size: 14px !important;
    margin-right: 4px !important;
    padding: 0px 10px !important;
  }

  .border-calendar {
    border-top: 2px solid #e4e5e7 !important;
    border-bottom: 1px solid #e4e5e7 !important;
    background: #f3f3f5 !important;
  }

  tr td:nth-child(2) {
    border-left: none !important;
  }

  .div-open-close {
    padding: 1px !important;
    border-bottom: 1px solid #e4e5e7 !important;
    height: 14px !important;
    flex-wrap: nowrap;
  }

  .div-day-calendar {
    vertical-align: middle !important;
    line-height: 1 !important;
    padding: 10px 0px !important;
    height: 40px !important;
    user-select: none !important;
  }

  .div-date-calendar {
    vertical-align: middle !important;
    height: 74px !important;
  }

  .cell-calendar {
    background: #f3f3f5 !important;
    user-select: none !important;
  }
}

.daily-overview-events {
  .cell-daily {
    height: 25px !important;
    position: relative;
  }

  .first-column {
    z-index: 4;
  }

  .events_label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    text-transform: uppercase;
    background-color: #f1f4f9;
  }

  .event-box {
    position: absolute;
    z-index: 2;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    top: 0;
    left: 0;
  }
  .tr-events {
    display: block !important;
  }
  .td-top {
    top: 4px;
  }
}
