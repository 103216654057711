.card-with-padding {
  & > .ant-card-body {
    padding: 60px !important;
  }
}

.card-no-padding {
  & > .ant-card-body {
    padding: 0px !important;
  }
}
