@use '../customized-scrollbar';
@use '../colors';

.stats-table {
  cdk-virtual-scroll-viewport {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'grey-light'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'grey-light')
    );
  }

  cdk-virtual-scroll-viewport:hover {
    @include customized-scrollbar.customizedScrollbar(
      map-get(colors.$colors, 'grey-light'),
      map-get(colors.$colors, 'white'),
      map-get(colors.$colors, 'grey-dark')
    );
  }

  .ant-table-row-expand-icon::after {
    left: 1px;
    top: -2px;
    background: transparent;
    font-family: 'Font Awesome 5 Pro';
    content: '\f067';
    font-size: 11px;
    color: #000000;
  }

  .ant-table-row-expand-icon-expanded::after {
    font-family: 'Font Awesome 5 Pro';
    content: '\f068';
    margin-left: 0px;
    background: transparent;
    transform: none;
    font-size: 11px;
    color: #000000;
  }

  .ant-table-row-expand-icon {
    border-radius: 3px;
    height: 14px;
    width: 14px;
    border-color: #000000;
    line-height: 1.3;
    border-width: 2px;
  }
  .ant-table .ant-table-cell-with-append {
    text-align: left;
  }

  .ant-table-thead > tr > th {
    text-align: left;
    padding-left: 11px;
  }

  .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 15%);
  }

  tr:not(.tr-background) {
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: #fff;
    }
  }

  .ant-table-header.nz-table-hide-scrollbar {
    overflow: hidden !important;
  }
}
