.bordered-radio-group {
  box-shadow: 0px 0 4px 0px rgb(126 142 177 / 35%);
  border-radius: 4px;

  & .ant-radio-wrapper {
    padding: 15px;
    margin: 0;
    user-select: none;

    &:not(:last-child) {
      border-right: 1px solid #e8e8e8;
    }
  }
}

.custom-radio-remove-focus-within.ant-radio-button-wrapper:focus-within {
  box-shadow: none !important;
}
