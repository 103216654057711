.main-navigation {
  padding-bottom: 50px;
  user-select: none;

  & .active-sub-li span label::before {
    background-color: #047bf8;
  }

  & .ant-menu-submenu-arrow {
    display: none;
  }

  & .ant-menu-submenu-title {
    padding: 0 15px;
    &::after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f078';
      color: #ffffff96;
      font-size: 10px;
      position: absolute;
      top: 2px;
      left: calc(#{$sidebar--width} - 30px);
    }
  }

  & .menu-custom-popup-li .ant-menu-submenu-title {
    padding: 0 15px;
    &::after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f054';
      color: #ffffff96;
      font-size: 10px;
      position: absolute;
      top: 2px;
      left: calc(#{$sidebar--width} - 20px);
    }
  }

  & .ant-menu-submenu-open > .ant-menu-submenu-title::after {
    content: '\f077';
    color: #fff;
  }

  & .menu-custom-popup-li.ant-menu-submenu-open .ant-menu-submenu-title::after {
    content: '\f053';
    color: #fff;
  }

  &.ant-menu-inline .ant-menu-item,
  &.ant-menu-vertical .ant-menu-item {
    padding: 0 15px;
  }
}
