@use '../customized-scrollbar';
@use '../grid-breakpoints';

.guest-card-service-modal {
  .ant-modal-content {
    .ant-modal-body {
      @include grid-breakpoints.grid-respond-to('before-medium') {
        padding: 24px 5px 24px 5px;
      }
    }
  }
}
