nz-date-picker {
  &.color--white {
    & input {
      color: #fff;
    }
  }

  &.bold {
    & input {
      font-weight: bold;
    }
  }

  &.pointer {
    & input {
      cursor: pointer;
    }
  }
}
