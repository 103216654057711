$tabWidth: 260px;
$tabPadding: 12px;

.invoice-tabset {
  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0;

    &::before {
      display: none;
    }
  }

  & .ant-tabs-ink-bar {
    display: none;
  }

  & .ant-tabs-tab {
    padding: $tabPadding;
    width: $tabWidth;
    margin: 0;

    &.ant-tabs-tab-active {
      background-color: #fff;
      padding: $tabPadding;
      text-align: left;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
      transition: all 0.5s;
    }
  }

  &.ant-tabs-left > .ant-tabs-content-holder,
  &.ant-tabs-left .ant-tabs-nav-operations {
    display: none;
  }

  &.ant-tabs-left {
    overflow: visible;

    & > .ant-tabs-nav {
      & .ant-tabs-tab {
        padding: $tabPadding $tabPadding 0;
        width: $tabWidth;
        text-align: left;
        margin: 0;

        & .ant-tabs-tab-btn {
          padding-bottom: $tabPadding;
          border-bottom: 1px solid #e1e1e1;
        }

        &.ant-tabs-tab-active {
          margin-top: -1px;
          border-top-right-radius: 0;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          box-shadow: -3px 2px 3px rgba(126, 142, 177, 0.12);

          & .ant-tabs-tab-btn {
            border-bottom: 0;
          }
        }
      }

      & .ant-tabs-nav-wrap {
        overflow: visible;

        &::after {
          box-shadow: none;
        }
      }
    }
  }

  & .ant-tabs-tab-btn {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .invoice-tabset {
    & > .ant-tabs-nav {
      display: none;
    }
  }
}
