.by-filters-collapse > .ant-collapse-item {
  .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 30px 10px;
  }
}

.by-filters-collapse.by-filters-collapse--no-padding > .ant-collapse-item {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
}
