@use '../grid-breakpoints';

.message-type-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 2px solid #fff;
}

.message-type-search.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 20px;
}

.message-type-select {
  @include grid-breakpoints.grid-respond-to('before-small') {
    width: 100%;
  }
}
