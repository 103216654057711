.by-tableau-actions-modal {
  & .ant-modal-header {
    padding: 10px 15px;
    padding-bottom: 0;
    border-bottom: none;
  }

  & .by-tableau-actions-modal-title {
    padding-bottom: 7px;

    &--bordered {
      border-bottom: 2px solid #f0f0f0;
    }
  }

  & .ant-modal-close {
    cursor: pointer;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &::before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f00d';
      font-size: 16px;
      position: absolute;
      color: #000;
    }

    &-x {
      width: 30px;
      height: 35px;
    }

    &-icon {
      display: none;
    }
  }

  & .ant-modal-footer {
    background-color: #f3f3f5;
  }

  &--blue-header {
    & .ant-modal-header {
      background-color: #047bf8;
    }

    & .ant-modal-close {
      &::before {
        color: #fff;
      }
    }

    & .ant-modal-title {
      color: #fff;
    }

    & .by-tableau-actions-modal-title {
      &--bordered {
        border-bottom: 1px solid #f3f3f37a;
      }
    }
  }
}
