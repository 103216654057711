.notifications-popover {
  width: 35vw;
  min-width: 300px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
