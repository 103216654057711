.vertical-align {
  &--middle {
    vertical-align: middle;
  }
}

.by-word-break {
  &--break-word {
    word-break: break-word;
  }
}

@for $i from 1 through 30 {
  .by-clamp-text-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
}

.by-break-spaces {
  white-space: break-spaces;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor {
  &--notAllowed {
    cursor: not-allowed !important;
  }
}

.by-default-card-width {
  max-width: 1200px;
}

.by-line-height-normal {
  line-height: normal;
}

.flex-grow-unset {
  flex-grow: unset !important;
}
