@use '../colors' as colors;
@use '../grid-breakpoints' as breakpoints;

$colors: colors.$colors;
$breakpoints: breakpoints.$gridBreakpoints;

.refs-table {
  & .ant-table-content > table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
}

@media only screen and (#{map-get($breakpoints, 'before-xLarge')}) {
  .bills-date-table,
  .bills-extra-table,
  .payments-table,
  .bills-accommodations-table {
    .refs-table-header-row {
      display: none;
    }
  }

  .refs-table.payments-table {
    & .ant-table-content > table {
      border-spacing: unset;
    }
  }

  .refs-table.payments-table {
    td {
      background-color: map-get($colors, 'white') !important;
      border-bottom: 1px solid #c0c8d5 !important;
      padding: 0 !important;
    }
  }
}
