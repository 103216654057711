.moved-bill-row:not(.reservation-details-bills-ref-responsive) {
  background-color: #fff !important;

  & > td:not(.no-opacity) {
    pointer-events: none;
    opacity: 0.3;
  }

  & > td.no-opacity {
    background: rgba(248, 249, 251, 0.3) !important;
  }
}

.trashed-bill-row:not(.moved-bill-row) {
  background-color: #f8e5e5 !important;

  & > td:not(.no-opacity) {
    pointer-events: none;
    opacity: 0.3;
  }

  & > td.no-opacity {
    background: rgba(248, 249, 251, 0.3) !important;
  }
}
