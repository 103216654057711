.crm-price-quotation-cart-edit {
  color: #293144;

  h3 {
    font-weight: 700;
  }

  .ant-row-flex {
    font-size: 15px;
  }

  &.cart-item {
    & .ant-card {
      border-radius: 4px;
      overflow: hidden;
      color: unset !important;
    }
  }

  & .cart-item {
    &__header {
      & .page-header {
        padding-top: 15px;
      }

      & > div {
        display: flex;
        align-items: center;
      }

      &--action-box {
        border-left: 1px solid #e7e8ec;
      }

      &--delete {
        & * {
          color: #e7503b;
          font-size: 16px;
          transition: all 0.3s;
        }

        &:hover * {
          color: #e7503b80;
        }
      }

      &--actions {
        & * {
          color: #047bf8;
          font-size: 16px;
          transition: all 0.3s;
        }

        &:hover * {
          color: #047bf87d;
        }
      }
    }
  }

  & .addon-row {
    margin: -5px 0;
  }
}

.big-font-row {
  font-size: 14px !important;
}
