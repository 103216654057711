by-dynamic-form-item-resolver .ant-form-inline {
  .by-form-item {
    .ant-form-item > .ant-form-item-control {
      height: 56px;
    }

    .ant-form-item-with-help {
      margin-bottom: 0;
    }
  }
}
