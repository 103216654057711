.price-details-popover.price-details-responsive {
  .popover-price-container {
    overflow: hidden;
    min-width: 270px;
    border: 0;
  }

  .popover-price-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 13px;
    padding: 0;
    border-bottom: 0;
  }

  .popover-price-list {
    list-style-type: none;
    padding: 0 20px;
    padding-bottom: 10px;

    li:nth-child(6) {
      font-weight: bolder;
    }
    li:nth-child(7) {
      font-weight: bolder;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 33px;
      border-bottom: 0;
      &:last-child {
        border-top: 1px solid #c5cfd7;
        border-bottom: 0;
      }
    }

    span,
    strong {
      text-transform: capitalize;
    }
  }

  .ant-popover-inner-content {
    padding: 0px;
    border-radius: 6px;
  }

  .ant-popover-inner {
    border-radius: 6px;
  }

  .pointer {
    cursor: pointer;
  }
}

.price-details-popover {
  .popover-price-container {
    overflow: hidden;
    min-width: 270px;
    border: 2px solid;
  }

  .popover-price-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 20px;
    border-bottom: 1px solid #c5cfd7;
  }

  .popover-price-list {
    list-style-type: none;
    padding: 20px;
    padding-bottom: 10px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 33px;
      border-bottom: 1px solid #c5cfd7;
      &:last-child {
        border-bottom: 0;
      }
    }

    span,
    strong {
      text-transform: capitalize;
    }
  }

  .ant-popover-inner-content {
    padding: 0px;
    border-radius: 6px;
  }

  .ant-popover-inner {
    border-radius: 6px;
  }

  .pointer {
    cursor: pointer;
  }
}

.price-details-popover__container {
  & .ant-popover-inner-content {
    padding: 0 !important;
    overflow: hidden;
    backface-visibility: hidden;
  }
  & .ant-popover-inner {
    overflow: hidden;
  }

  &.ant-popover-placement {
    &-bottom,
    &-top,
    &-left,
    &-right {
      & > .ant-popover-content {
        border-color: inherit;

        & > .ant-popover-arrow {
          border-color: inherit;
          z-index: -1;
        }
      }
    }
  }

  & .price-details-popover {
    max-width: unset !important;
  }
}
