@import './../../variables';

.daily-overview-treatment-modal {
  & .form-content {
    padding: 10px 15px;
  }

  & .modal-padding {
    padding: 15px;
  }

  & .page-header {
    background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
    padding: 20px;
    margin: 0;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.collapsed {
      padding: 5px;
      justify-content: space-evenly;
    }
  }

  & .ant-modal-content {
    overflow: hidden;
  }

  & .ant-modal-header {
    padding: 7px 18px 0px 18px;
    border-bottom: none;
    font-weight: bold;
    font-size: 12pt;
    background-color: map-get($tableau-modal-colors, 'modalHeader-bg');

    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: rgba(map-get($tableau-modal-colors, 'grey-light'), 0.3);
    }
  }

  & .ant-modal-close-x {
    line-height: 35px;
    width: 36px;
    height: 36px;
    color: map-get($tableau-modal-colors, 'white');
  }

  & .ant-modal-close {
    cursor: pointer;
    padding-right: 5px;

    &::after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f00d';
      font-size: 16px;
      position: absolute;
      color: #fff;
      top: 50%;
      transform: translateY(-50%);
    }

    &-icon {
      display: none;
    }
  }

  & .ant-modal-title,
  & .ant-modal-body,
  & .ant-form-item-label label {
    color: map-get($tableau-modal-colors, 'white');
    font-size: 12px;
  }

  & .ant-modal-title {
    padding: 7px 0;
  }

  & .ant-btn {
    font-size: 12px !important;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  & .ant-modal-mask {
    background-color: transparent !important;
  }

  & .ant-modal-body {
    padding: 0;
  }

  & .ant-calendar-picker {
    font-size: 12px !important;
    width: 92px !important;
  }

  & .ant-form-item-label {
    line-height: unset !important;
    padding: unset;
  }
  & .ant-form-item-label label {
    margin: 0px !important;
    @include tableauFormFont;
  }

  & .ant-form-item {
    margin: 0px !important;
  }

  & .ant-form-item-control {
    line-height: 35px;
    text-align: right;
  }

  & .date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 25px 30px;

    &__to,
    &__from,
    &__number-night {
      display: flex;
      flex-direction: column;
    }

    &__number-night {
      line-height: 1.3;
    }

    &--label {
      color: map-get($tableau-modal-colors, 'blue-dark');
      font-size: 12px;
    }

    &--value {
      font-size: 16px;
      font-weight: bold;
      color: map-get($tableau-modal-colors, 'white');
    }

    & > i {
      font-size: 12px;
      color: map-get($tableau-modal-colors, 'white');
    }
  }

  & input,
  & nz-select,
  & textarea & .input-group-text-custom {
    @include tableauFormFont;
  }

  & .ng-trigger-helpMotion {
    @include tableauFormExplainFont;
  }

  & .new_reservation__step1 {
    & .ant-picker {
      width: 92px !important;
      background-color: map-get($tableau-modal-colors, 'modalHeader-bg');

      & input {
        background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
        color: map-get($tableau-modal-colors, 'white') !important;
        border: none;
        padding: 0;
        font-size: 16px !important;
        font-weight: bold;
        height: 20px;
        cursor: default;
      }

      & i {
        display: none;
      }
    }
  }

  &.new_note {
    & .ant-calendar-picker {
      width: 92px !important;

      & input {
        background-color: map-get($tableau-modal-colors, 'modalHeader-bg');
        color: map-get($tableau-modal-colors, 'white') !important;
        border: none;
        padding: 0;
        font-size: 16px !important;
        font-weight: bold;
        height: 20px;
        cursor: default;
      }

      & i {
        display: none;
      }
    }
  }

  & .ant-picker {
    background-color: #047bf8;
    padding: 0;
    border: 0;

    input {
      background-color: #047bf8;
      color: #ffffff !important;
      border: none;
      padding: 0;
      font-size: 16px !important;
      font-weight: bold;
      height: 20px;
      cursor: default;
    }
  }

  & .anticon-calendar {
    display: none;
  }

  & .ant-modal-footer {
    padding: 0;
  }

  & .footerModal {
    background-color: #f3f3f5;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
  }
}
.daily-overview-treatment-modal-footer-padding {
  & .ant-modal-footer {
    padding: 10px !important;
  }
}
