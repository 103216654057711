.loading-modal-template {
  width: 100%;
  text-align: center;

  & > img {
    width: 250px;
    border-radius: 25px;
  }

  & > strong {
    margin-bottom: 20px;
    display: block;
    font-size: 14px;
  }
}
