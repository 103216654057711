@use '../grid-breakpoints';

.by-crm-fixed-bar {
  by-button-mobile {
    .cart-button {
      &--no-border {
        border: none;
        box-shadow: none;
      }
    }

    .cart-button.ant-btn {
      padding: 6px 15px;
    }

    .cart-button.ant-btn:focus {
      background: #888585;
    }
    .cart-button {
      background-color: #888585;
      border-radius: 4px;
      color: #ffff;
      transition: background-color 0.3s;

      &--animate {
        background-color: #047bf8;
      }
    }
  }

  by-button-mobile:hover {
    .total-price.ant-btn {
      padding: 6px 15px;
    }
    .total-price.ant-btn:focus {
      background: #888585;
    }
    .total-price {
      background-color: #888585;
      border-radius: 4px;
      color: #ffff;
      transition: background-color 0.3s;

      &--animate {
        background-color: #047bf8;
      }
    }
  }

  .button-no-border {
    border: none;
  }
}
