.by-pt-0 {
  padding-top: 0 !important;
}

.by-p-2 {
  padding: 0.5rem !important;
}

.by-p-5 {
  padding: 5px !important;
}

.by-p-10 {
  padding: 10px !important;
}

.by-p-25 {
  padding: 25px !important;
}

.by-p-40 {
  padding: 40px !important;
}

@for $i from 0 through 36 {
  .by-p-#{$i} {
    padding: #{$i}px !important;
  }
}

.by-pr-4,
.by-px-4 {
  padding-right: 1.5rem !important;
}

.by-pl-03rem {
  padding-left: 0.3rem !important;
}

.by-pl-2 {
  padding-left: 0.9rem !important;
}
.by-pl-20 {
  padding-left: 20px !important;
}
.by-pl-25 {
  padding-left: 25px !important;
}

.by-pr-0 {
  padding-right: 0px !important;
}

.by-pr-2 {
  padding-right: 0.9rem !important;
}

.by-pr-20px {
  padding-right: 20px;
}

.by-pr-10px {
  padding-right: 10px !important;
}

.by-pr-5 {
  padding-right: 5px !important;
}

.by-pr-15 {
  padding-right: 15px;
}

.by-pl-15 {
  padding-left: 15px;
}

.by-pr-40 {
  padding-right: 40px !important;
}

.by-pl-40 {
  padding-left: 40px !important;
}

@for $i from 0 through 36 {
  .by-pt-#{$i} {
    padding-top: #{$i}px !important;
  }
}

.by-pt-2 {
  padding-top: 0.9rem !important;
}

.by-pt-5 {
  padding-top: 5px !important;
}

.by-pt-10 {
  padding-top: 10px !important;
}

.by-pt-20 {
  padding-top: 20px;
}

.by-pl-30 {
  padding-left: 30px !important;
}

.by-pl-0 {
  padding-left: 0px !important;
}

.by-pl-5 {
  padding-left: 5px !important;
}

.by-pl-10 {
  padding-left: 10px !important;
}

.by-pb-0 {
  padding-bottom: 0px !important;
}

.by-pb-5 {
  padding-bottom: 5px !important;
}

.by-pb-10 {
  padding-bottom: 10px !important;
}

.by-pb-15 {
  padding-bottom: 15px !important;
}

.by-pb-20 {
  padding-bottom: 20px;
}

.by-pb-30 {
  padding-bottom: 30px;
}

.by-px-2 {
  padding: 0 20px;
}

.by-px-18 {
  padding: 0 18px;
}

.by-px-32 {
  padding: 0 32px !important;
}
