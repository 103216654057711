ngx-charts-legend.chart-legend {
  display: block;

  & .legend-labels {
    max-height: unset;
    overflow: visible;
    white-space: initial;
  }

  & .legend-label {
    margin: 0;
    padding: 5px;
  }
}

.ngx-charts {
  margin-bottom: 20px;
  overflow: visible !important;
}
