.newsletter-result {
  .ant-result {
    padding: 20px 0 0;
  }
  .ant-result-icon,
  .ant-result-extra {
    margin: 0;
  }
  .ant-result-icon {
    margin-bottom: 20px;
  }
  .ant-result-extra {
    margin-top: 20px;
  }
}
