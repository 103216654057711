@use './../colors';

$font-size-lg: 25px;

$font-size-sm: 13px;

.by-stats-range-picker.ant-picker-range {
  & .ant-picker-input input {
    color: map-get(colors.$colors, 'white');
    font-weight: bold;
    text-align: start;
  }

  & div.ant-picker-input:nth-child(3) input {
    text-align: end;
  }

  & .ant-picker-range-separator {
    padding: 0;

    .ant-picker-separator {
      color: map-get(colors.$colors, 'white');
      font-weight: bold;
      width: auto;
      height: auto;
    }
  }

  & .ant-picker-active-bar {
    display: none;
  }
}

.by-stats-range-picker {
  &--large {
    width: 320px;

    & .ant-picker-input input {
      font-size: $font-size-lg;
    }

    & .ant-picker-range-separator .ant-picker-separator {
      font-size: $font-size-lg;
    }
  }

  &--small {
    width: 170px;

    & .ant-picker-input input {
      font-size: $font-size-sm;
    }

    & .ant-picker-range-separator .ant-picker-separator {
      font-size: $font-size-sm;
    }
  }

  &__suffix-icon {
    &--large {
      & .ant-picker-input {
        font-size: $font-size-lg;
      }
    }

    &--small {
      & .ant-picker-input {
        font-size: $font-size-sm;
      }
    }
  }
}
