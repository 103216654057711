// nascondere/visualizzare limage uploader in base alla risoluzione
@media screen and (min-width: 992px) {
  .hide-under-992 {
    display: block;
  }

  .hide-over-992 {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .hide-under-992 {
    display: none;
  }

  .hide-over-992 {
    display: block;
  }

  .addons-mobile-image-uploader {
    margin-bottom: 20px;
  }
}
