.admin-menu {
  &--tablet {
    .ant-menu-item {
      text-align: center;
    }
    .ant-menu-submenu-title {
      text-align: center;
      padding-left: 24px;
    }
    .ant-menu-submenu-arrow {
      display: none;
    }
  }
}
