$icons: (
  'by-no-room-icon': '/assets/icons/no-room.svg',
  'by-child-room-icon': '/assets/icons/child-room.svg',
  'by-virtual-room-icon': '/assets/icons/virtual-room.svg',
  'by-sum-room-icon': '/assets/icons/sum-room.svg',
  'by-master-room-icon': '/assets/icons/master-room.svg',
  'by-sum-room-child-icon': '/assets/icons/sum-room-child.svg',
);

.by-icon {
  mask-repeat: no-repeat;
  mask-size: cover;
  background-color: #334455;
  display: inline-block;
  height: 13px !important;
  width: 13px !important;

  @each $iconClass, $iconUrl in $icons {
    &.#{$iconClass} {
      mask-image: url(#{$iconUrl});
    }
  }
}
